















import { Component, Mixins, VModel } from 'vue-property-decorator'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { ButtonStruct } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

@Component
export default class CheckButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: ButtonStruct
}
