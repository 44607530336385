export default {
    SET_DRAFTS_TO_STORE: (state, payload) => {
        state.draftsState.drafts = payload
    },
    ADD_DRAFT_TO_STORE: (state, payload) => {
        if (!state.draftsState.drafts) {
            state.draftsState.drafts = []
        }

        state.draftsState.drafts.push(payload)
    },
    REMOVE_DRAFT: (state, postTokenId: number) => {
        state.draftsState.drafts = state.draftsState.drafts!.filter(draft => draft.key.token_id !== postTokenId)
    },
    CLEAR_DRAFTS_FROM_STORE: (state) => {
        state.draftsState.drafts = null
    },
}
