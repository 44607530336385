



















































import { CommentsButton } from '.'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver,
  },
})
export default class CommentsButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: CommentsButton

  @Prop({ type: String }) readonly botName!: string

  get targetsWithoutLinkedGroup() {
    return this.$store.state.targetsWithoutLinkedGroup
  }

  get targetsWithoutLinkedGroupTitles() {
    if (this.targetsWithoutLinkedGroupExists) {
      return this.targetsWithoutLinkedGroup.map(t => t.title).join(', ')
    }

    return ''
  }

  get targetsWithoutLinkedGroupExists() {
    return Array.isArray(this.targetsWithoutLinkedGroup) && !!this.targetsWithoutLinkedGroup.length
  }

  get isFieldDisabled() {
    return (!this.targetsWithoutLinkedGroup || this.targetsWithoutLinkedGroupExists) && this.$store.state.validateCommentButtonHasGroupFields
  }
}
