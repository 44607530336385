import { IntegrationRequest } from "../IntegrationRequest/IntegrationRequest";
import { IntegrationRequestTypeEnum } from "../types";

export class ChangePointsRequest extends IntegrationRequest {
  type: IntegrationRequestTypeEnum = IntegrationRequestTypeEnum.ChangePoints;

  value!: number

  lower_limit!: number
  upper_limit!: number

  reason!: string
}