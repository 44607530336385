
















import ChannelMemberBuilder from "./ChannelMemberBuilder";

import { IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory';
import ChannelsInfoHelper from "piramis-base-components/src/components/Flow/new/logic/Helpers/ChannelsInfoHelper";
import { SuccessChannelInfo } from "piramis-base-components/src/models/CheckChannels/CheckChannels";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ChannelMemberPreview extends Vue {
  @Prop() condition!: IterableListItem<ChannelMemberBuilder>

  get beautifiedTargets() {
    return this.condition.value.model.targets.map(id => {
      const target = ChannelsInfoHelper.GetChannel(id.toString())

      const isSuccessChannelInfo = target instanceof SuccessChannelInfo
      const channelTitle = isSuccessChannelInfo ? ` (${ target.info.title })` : ''

      return {
        title: id + channelTitle,
        color: isSuccessChannelInfo ? 'rgba(var(--a-success), 1)' : 'rgba(var(--a-danger), 1)'
      }
    })

  }
}
