




































import { IntegrationNodeCustom } from './IntegrationCustom';

import { ButtonView } from 'piramis-base-components/src/components/Flow/new/components'
import { SocketNames } from "piramis-base-components/src/components/Flow/new/components/Socket/SocketsHelper";
import { SampleNodeMixin } from 'piramis-base-components/src/components/Flow/new/logic/Nodes';

import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    ButtonView
  },
  data() {
    return {
      SocketNames
    }
  }
})
export default class IntegrationSampleNodeCustom extends SampleNodeMixin {
  @Prop() node!: IntegrationNodeCustom

  get type() {
    return this.i18n.t(this.node.integrationTitleKey(this.node.data.data.type))
  }
}
