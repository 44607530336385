import { RootState } from '@/store/state'
import { Product, ProductState } from 'piramis-base-components/src/components/Shop/Products/types'
import { CashRegisterModel, CashRegistersState } from 'piramis-base-components/src/components/Shop/CashRegisters/types'

export default {
	set_products_to_store(state: RootState, items: Array<Product>): void {
		state.shopState.products = items
	},
	set_product_to_store(state: RootState, data: Product): void {
		state.shopState.products!.push(data)
	},
	edit_product_in_store(state: RootState, product: Product): void {
		const oldProduct = state.shopState.products!.findIndex((old) => old.id === product.id)

		if (oldProduct !== -1) {
			state.shopState.products!.splice(oldProduct, 1, product)
		}
	},
	delete_product_from_store(state: RootState, { productState, id }: { productState: ProductState.Hidden | ProductState.Removed, id: number }): void {
		if (state.shopState.products) {
			const product = state.shopState.products.find((p) => p.id === id)

			if (product) {
				product.state = productState
			}
		}
	},
	restore_product(state: RootState, id: number): void {
		if (state.shopState.products) {
			const product = state.shopState.products.find((p) => p.id === id)

			if (product) {
				product.state = ProductState.Active
			}
		}
	},
	set_cash_registries_to_store(state: RootState, items: Array<any>): void {
		state.shopState.cashRegisters = items
	},
	set_cash_register_to_store(state: RootState, newCash: CashRegisterModel): void {
		state.shopState.cashRegisters!.push(newCash)
	},
	edit_cash_register_in_store(state: RootState, newCash: CashRegisterModel): void {
		const old = state.shopState.cashRegisters!.findIndex((cash) => cash.id === newCash.id)

		if (old !== -1) {
			state.shopState.cashRegisters!.splice(old, 1, newCash)
		}
	},
	delete_cash_register_from_store(state: RootState, { id, cashState }: { id: number, cashState: CashRegistersState }): void {
		const index = state.shopState.cashRegisters!.findIndex((cash) => cash.id === id)

		if (index !== -1) {
			if (cashState === CashRegistersState.Hidden) {
				state.shopState.cashRegisters![index].state = CashRegistersState.Active
			} else {
				state.shopState.cashRegisters!.splice(index, 1)
			}
		}
	},
	reset_shop_state_fields(state: RootState): void {
		state.shopState.cashRegisters = null
		state.shopState.products = null
	},
}
