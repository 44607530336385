import { errorNotification } from '@/includes/services/NotificationService'
import { RootState } from "@/store/state";
import MetaDataService from '@/includes/services/MetaDataService'
import { MenuOptions } from '@/includes/types/MenuOptions.types';

import { Commit } from 'vuex'

export default {
    getMenuOptions({ commit, state }: { commit: Commit, state: RootState }): Promise<MenuOptions | void> {
            return MetaDataService.getMenuOptions('tg', { board_key: state.boardsState.activeBoard!.board })
                .then((data) => {
                    commit('SET_MENU_OPTIONS', data)

                    return data
                })
                .catch(errorNotification)
    }
}
