



























































































































import { hiddenTargetTypeOptions } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import HiddenTargetTypesViews
  from '@/components/ProjectButtons/includes/components/HiddenTargetTypesViews/HiddenTargetTypesViews.vue'
import { HiddenSubscriberLinkButton } from '.'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import PiTabs from 'piramis-base-components/src/components/PiTabs.vue'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

enum Tabs {
  subscribed,
  unsubscribed,
  enough_subscribed,
}

@Component({
  data() {
    return {
      Tabs,
    }
  },
  components: {
    HiddenTargetTypesViews,
    ValidationObserver,
    PiTabs
  },
})
export default class HiddenSubscriberLinkButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: HiddenSubscriberLinkButton

  @Prop({ 'type': Function }) readonly getArticlesFn!: () => Promise<Array<SelectOptionData>>

  activeTab: Tabs = Tabs.subscribed

  tabs: Array<SelectOptionData> = [
    {
      label: this.$t('hidden_subscriber_link_subscribed').toString(),
      value: Tabs.subscribed,
      icon: {
        iconPack: 'material-icons',
        name: 'content_paste_go'
      }
    },
    {
      label: this.$t('hidden_subscriber_link_unsubscribed').toString(),
      value: Tabs.unsubscribed,
      icon: {
        iconPack: 'material-icons',
        name: 'content_paste_off'
      }
    },
    {
      label: this.$t('hidden_subscriber_link_not_enough_subscribed').toString(),
      value: Tabs.enough_subscribed,
      icon: {
        iconPack: 'material-icons',
        name: 'content_paste'
      }
    },
  ]

  onNotEnoughSubscribedTargetTypeChange(): void {
    this.model.notEnoughSubscribedTargetValue = ''
  }

  onSubscribedTargetTypeChange(): void {
    this.model.subscribedTargetValue = ''
  }

  onUnsubscribedTargetTypeChange(): void {
    this.model.unsubscribedTargetValue = ''
  }

  hiddenTargetOptions(): Array<SelectOptionData> {
    return hiddenTargetTypeOptions().map((o) => {
      return {
        label: this.$t(o.label).toString(),
        value: o.value
      }
    })
  }
}
