import { Exclude, instanceToPlain, plainToInstance, Transform, Type } from 'class-transformer'

import {
  ConfigData,
  ConfigDataScheme,
  FlowAccessor,
  FlowConfig
} from "piramis-base-components/src/components/Flow/new/types";
import { UserActivationAction } from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction';

import { TransformerClasses } from "./Conditions/includes";
import { AccessorCondition } from "@/components/Flow/Accessor/Conditions/AccessorCondition";
import { ChannelMember } from './Conditions/ChannelMember';

export class ProjectFlowAccessor extends FlowAccessor {

  @Transform(({ value }) => {
    return value.map((r: Array<any>) => {
      return r.map((b) => {
        const factory = TransformerClasses.find((f) => f.name === b.type)

        if (factory) {
          return plainToInstance(factory.value, b)
        }

        return AccessorCondition
      })
    })
  })
  conditions!: Array<Array<AccessorCondition>>

  action!: UserActivationAction | null

  @Exclude()
  isDrawerVisible = false

  getModel() {
    return instanceToPlain(this);
  }

  accessorButtonClick(): void {
    this.isDrawerVisible = true
  }

  getChannelMemberConditionTargets() {
    const targets: Array<number> = []

    this.conditions.forEach(g => {
      g.forEach(c => {
        if (c instanceof ChannelMember) {
          targets.push(...c.targets)
        }
      })
    })

    return targets
  }
}

export class ProjectConfigDataScheme extends ConfigDataScheme {
  @Type(() => ProjectFlowAccessor)
  declare accessor: null | ProjectFlowAccessor

  initAccessorModel() {
    this.accessor = plainToInstance(ProjectFlowAccessor, {
      conditions: [],
      action: null
    })
  }
}

class ProjectConfigData extends ConfigData {
   @Type(() => ProjectConfigDataScheme)
   declare scheme: ProjectConfigDataScheme
}

export class ProjectFlowConfig extends FlowConfig {
  @Type(() => ProjectConfigData)
  declare data: ProjectConfigData
}
