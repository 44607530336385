import setupView from './RunFlowButtonSetupView.vue'
import buttonView from './RunFlowButtonView.vue'

import Button, { ButtonStruct, BaseButtonProps } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import { SelectInputOptions } from 'piramis-base-components/src/components/Pi/fields/SelectInput/types'

import { cloneDeep } from 'lodash'
import { Guid } from 'guid-typescript'

export type RunFlowButtonDataType = {
  flow: string
  utm: string
  text: string
}

type Props = {
  getFlowsOptions: SelectInputOptions
  getUtmSourcesOptions: SelectInputOptions
}

export default class RunFlowButton extends Button {
  name = 'RunFlowButton'

  setupView = setupView
  buttonView = buttonView

  data: RunFlowButtonDataType = this.getRawData()

  icon = '🎬'

  constructor(props: BaseButtonProps<Props>) {
    super(props)
  }

  getRawData(): RunFlowButtonDataType {
    return {
      text: this.$i18n.t('initial_text_message_editor_buttons').toString(),
      flow: '',
      utm: '',
    }
  }

  create(): RunFlowButtonStruct {
    const struct = new RunFlowButtonStruct({
      ...cloneDeep(this.data),
      guid: Guid.create().toString(),
      type: this.name
    })
    this.refreshData()

    return struct
  }
}

export class RunFlowButtonStruct extends ButtonStruct {
  flow: string
  utm: string

  constructor(struct: RunFlowButtonStruct) {
    super(struct)

    const { flow, utm } = struct
    this.flow = flow
    this.utm = utm
  }
}
