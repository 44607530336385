var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"integration-sidebar-custom"},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.node,
        'key': 'requestType',
        'prefix': 'integration_',
        'options': _vm.typeOptions,
        'clearable': false,
      },
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.node,
        'key': 'token',
        'prefix': 'integration_',
        'validation': 'required',
      },
    }}}),(_vm.node.builders[_vm.node.data.data.type])?_c(_vm.node.builders[_vm.node.data.data.type].settings,{tag:"component",model:{value:(_vm.node.data.data),callback:function ($$v) {_vm.$set(_vm.node.data, "data", $$v)},expression:"node.data.data"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }