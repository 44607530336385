import BaseApi from "@/includes/services/BaseApi";
import { AddBoardPropertyReq, DeleteBoardPropertyReq, RenameBoardPropertyReq } from '@/includes/types/Properties.types'
import { BotProperty } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

export class PropertiesService {

	/**
	 * @deprecated
	 */
	static createProperty(type: string, body: { board_key: string, name: string, type: string }): Promise<{ index: number }> {
		return BaseApi.sendRequest(type, 'CreateProperty', body)
	}

	/**
	 * @deprecated
	 */
	static deleteProperty(type: string, body: { board_key: string, index: number }): Promise<any> {
		return BaseApi.sendRequest(type, 'DeleteProperty', body)
	}

	//new methods
	static addBoardProperty(type: string, body: AddBoardPropertyReq): Promise<{items: Array<BotProperty> }> {
		return BaseApi.sendRequest(type, 'addboardproperty', body)
	}

	static renameBoardProperty(type: string, body: RenameBoardPropertyReq): Promise<{items: Array<BotProperty> }> {
		return BaseApi.sendRequest(type, 'renameboardproperty', body)
	}

	static deleteBoardProperty(type: string, body: DeleteBoardPropertyReq): Promise<{items: Array<BotProperty> }> {
		return BaseApi.sendRequest(type, 'deleteboardproperty', body)
	}

}
