import buttonView from './PopupButton.vue'
import setupView from './PopupButtonSetupView.vue'
import { Guid } from 'guid-typescript'
import BaseButtonBuilder
    , { BaseButtonViewData } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import i18n from 'vue-i18n'
import { BaseButton, ButtonTypes } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { plainToInstance } from 'class-transformer'
import { BaseFlowButtonWithOutput } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/BaseFlowButtonWithOutput'

export default class PopupButtonButtonBuilder extends BaseButtonBuilder {
    type = ButtonTypes.PopupButton
    titleKey = ButtonTypes.PopupButton

    setupView = setupView
    buttonView = buttonView

    data = new PopupButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

    constructor(i18n: i18n) {
        super(i18n)
    }

    icon = '💻'

    create(): BaseButton {
        return plainToInstance(PopupButton, {
            ...this.data,
            type: ButtonTypes.PopupButton,
            guid: Guid.create().toString(),
        })
    }

    getData(button?: PopupButton): PopupButtonData {
        return new PopupButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
    }
}

export class PopupButtonData implements BaseButtonViewData {
    text = ''
    message = ''
    delay = 0

    constructor(data: PopupButton | string) {
        if(data instanceof PopupButton || typeof data === 'object') {
            const { text, message, delay } = data

            this.text = text
            this.message = message
            this.delay = delay
        } else {
            this.text = data
        }
    }
}

export class PopupButton extends BaseFlowButtonWithOutput {
    declare type: ButtonTypes.PopupButton
    message!: string
    delay!: number
}
