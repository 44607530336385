import { RootState } from '@/store/state'
import { SelectOption } from "piramis-base-components/src/logic/types";

export default {
	getLabelsOptions: (state: RootState): Array<SelectOption> => {
		if (state.boardsState.activeBoard) {
			return Object.entries(state.boardsState.activeBoard.config.labels).map(([ index, name ]) => {
				return {
					label: name,
					value: Number(index)
				}
			})
		}

		return []
	},
	labels(state: RootState) {
		if (state.boardsState.activeBoard) {
			return state.boardsState.activeBoard.config.labels
		}

		return {}
	},
}
