import setupView from './ShareTextButtonSetupView.vue'
import buttonView from './ShareTextButtonView.vue'
import BaseButtonBuilder, { BaseButtonViewData } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import { plainToInstance } from 'class-transformer'
import { BaseButton, } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { Guid } from 'guid-typescript'
import { ProjectButtonTypes } from '@/components/ProjectButtons/New/ProjectButtonTypes'

export default class ShareTextButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.ShareTextButton

  titleKey = ProjectButtonTypes.ShareTextButton

  setupView = setupView
  buttonView = buttonView

  data = new ShareTextButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  icon = '📢 🔗'

  create(): BaseButton {
    return plainToInstance(ShareTextButton, {
      ...this.data,
      type: ProjectButtonTypes.ShareTextButton,
      guid: Guid.create().toString(),
    })
  }

  getData(button?: ShareTextButton): ShareTextButtonData {
      return new ShareTextButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }
}

export class ShareTextButtonData implements BaseButtonViewData {
  text = ''
  message = ''
  url = ''

  constructor(data: ShareTextButton | string) {
    if(data instanceof ShareTextButton || typeof data === 'object') {
      const { message, url, text } = data

      this.message = message
      this.url = url
      this.text = text
    } else {
      this.text = data
    }
  }
}

export class ShareTextButton implements BaseButton {
  type!: ProjectButtonTypes.ShareTextButton
  guid!: string
  text!: string
  message!: string
  url!: string
}
