import { BoardsApi } from '@/includes/Api/Boards.api'
import { Board, SlimBoardInfo, SlimChannelInfo } from '@/includes/types/Board.types'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { RootState } from '@/store/state'
import i18n from '@/i18n/i18n'

import { Commit, Dispatch } from 'vuex'

export default {
    createBoard({ commit }: { commit: Commit }, body: { token: string, timezone:string }) {
            return BoardsApi.createBoard('tg', body)
              .then((board) => {
                  commit('set_board_to_store', board)

                  return board
              })
              .catch(errorNotification)
    },
    requestBoards({ commit }: { commit: Commit }): Promise<Array<SlimBoardInfo> | void> {
            return BoardsApi.getBoards('tg')
                .then((data) => {
                    commit('set_boards_to_store', data.boards)

                    return data.boards
                })
                .catch(errorNotification)
    },
    getBoardConfig({ commit }:{commit:Commit}, board_key:string):Promise<Board | void> {
            return BoardsApi.getPostBoardConfig('tg', { board_key })
                .then(boardConfig => {
                    commit('set_active_board_config', boardConfig)

                    return boardConfig
                })
                .catch(errorNotification)
    },
    savePostBoardConfig({ commit, state, dispatch }: { commit: Commit, state:RootState, dispatch: Dispatch }) {
        return BoardsApi.setPostBoardConfig('tg', {
            board_key: state.boardsState.activeBoard!.board,
            config: state.boardsState.activeBoard!.config,
            lang: state.boardsState.activeBoard!.lang,
            timezone: state.boardsState.activeBoard!.timezone
        })
            .then((resConfig) => {
                commit('set_active_board_config', resConfig)
                successNotification()
            })
            .then(() => {
                return dispatch('getBoardConfig', state.boardsState.activeBoard!.board)
            })
            .catch(errorNotification)
    },
    updateBoardChannel({ commit, state }: { commit: Commit, state: RootState }, data: { board: SlimBoardInfo['board'], channelId: SlimChannelInfo['id'], state: SlimChannelInfo['test_state'] }) {
        commit('update_board_channel', data)
    },
    activateBoardLicence({ commit }: { commit: Commit }, board_key: string): Promise<SlimBoardInfo | void> {
            return BoardsApi.activateBoardLicence('tg', { board_key })
                .then(({ board }) => {
                    successNotification(i18n.t('free_prime_license_activated').toString())

                    commit('set_board_to_store', board)

                    return board
                })
                .catch(errorNotification)
    }
}
