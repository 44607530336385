var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[(!_vm.$store.state.hasSelectedPostTargetsLinkedChat && _vm.$store.state.validateCommentButtonHasGroupFields)?_c('a-alert',{staticClass:"my-2 mx-2",attrs:{"type":"error","show-icon":""}},[_c('span',{attrs:{"slot":"description"},domProps:{"innerHTML":_vm._s(_vm.$t('group_not_connected_alert', [_vm.botName]))},slot:"description"})]):_vm._e(),_c('div',[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'comments_button_',
          'validation': 'required',
          'disabled': !_vm.$store.state.hasSelectedPostTargetsLinkedChat && _vm.$store.state.validateCommentButtonHasGroupFields,
        },
      }}}),_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'emptyText',
          'prefix': 'comments_button_',
          'validation': 'required',
          'disabled': !_vm.$store.state.hasSelectedPostTargetsLinkedChat && _vm.$store.state.validateCommentButtonHasGroupFields,
        },
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }