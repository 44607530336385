import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { ChannelService } from '@/includes/services/ChannelService'
import { Slot, ChannelInfo } from '@/includes/types/Board.types'
import { RootState } from '@/store/state'
import store from '@/store/store'

import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Commit, Dispatch } from 'vuex'
import { Route } from 'vue-router'

export default {
    getChannelConfig({ commit }: {commit: Commit}, payload: {board_key:string, channel_id:number}):Promise<ChannelInfo | void> {
            return ChannelService.getChannelConfig('tg', {
                board_key: payload.board_key,
                channel_id: payload.channel_id
            })
                .then(channel => {
                    commit('set_active_channel_to_store', channel)

                    return channel
                })
                .catch(errorNotification)
    },
    set_active_channel({ commit }: { commit: Commit }, payload: ChannelInfo): void {
        commit('set_active_channel_to_store', payload)
    },
    reset_active_channel({ commit }: { commit: Commit }): void {
        commit('reset_active_channel_from_store')
    },
    updateBoardChannelSubscriptionType({ commit, state }: { commit: Commit, state: RootState }, payload: { boardKey:string, target:ChannelInfo }):void {
        commit('update_board_channel_sub_type', payload)
    },
    save_active_channel_config({ state, dispatch, getters }: { state: RootState, dispatch: Dispatch, getters: any }, route: Route): void {
        store.commit('AdminTemplate/TOGGLE_GLOBAL_LOADER', true)

        if (state.boardsState.boards) {
            const currentBoard = getters.getBoardById(route.params[ EntityTypes.BOT_ID ])

            if (currentBoard && state.channelsState.activeChannel) {
                ChannelService.setChannelConfig('tg', {
                    board_key: currentBoard.board,
                    channel_id: Number.parseInt(route.params[EntityTypes.CHANNEL_ID]),
                    config: state.channelsState.activeChannel.config
                })
                    .then((response) => {
                        successNotification()
                        dispatch('set_active_channel', response)
                            .then(() => {
                                dispatch('updateBoardChannelSubscriptionType', { boardKey: currentBoard.board, target: response })
                            })
                    })
                    .catch(errorNotification)
                    .finally(() => store.commit('AdminTemplate/TOGGLE_GLOBAL_LOADER', false))
            } else {
                throw new Error('Board not found')
            }
        }
    },
    set_copied_slots({ commit }: { commit: Commit }, payload: Array<Slot>): Promise<void> {
        commit('set_copied_slot_to_store', payload)
        return Promise.resolve()
    },
    reset_copied_slots({ commit }: { commit: Commit }, payload: Array<Slot>): Promise<void> {
        commit('reset_copied_slot_from_store', payload)
        return Promise.resolve()
    },
    set_slots_to_config({ commit }: { commit: Commit }, payload: Array<Slot>): Promise<void> {
        commit('set_slots_to_config', payload)
        return Promise.resolve()
    }
}