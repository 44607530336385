import setupView from './ShareTextButtonSetupView.vue'
import buttonView from './ShareTextButtonView.vue'
import Button, { ButtonStruct } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'

export type ShareTextButtonDataType = {
  text: string
  message: string
  url: string
}

export default class ShareTextButton extends Button {
  name = 'ShareTextButton'

  setupView = setupView
  buttonView = buttonView

  data: ShareTextButtonDataType = this.getRawData()

  structBuilder = ShareTextButtonStruct

  icon = '📢 🔗'

  getRawData(): ShareTextButtonDataType {
    return {
      text: this.$i18n.t('initial_text_message_editor_share_button').toString(),
      message: '',
      url: ''
    }
  }
}

export class ShareTextButtonStruct extends ButtonStruct {
  message: string
  url: string

  constructor(struct: ShareTextButtonStruct) {
    super(struct)

    const { message, url } = struct

    this.message = message
    this.url = url
  }
}
