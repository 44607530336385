var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-answer-button-setup-view"},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'validation': 'required',
      },
    }}}),_c('create-if-empty',{attrs:{"options":_vm.getFormsOptions,"empty-message":_vm.$t('pi_flows_create_empty_message')},on:{"onCreateClick":_vm.createFormButtonCallback}},[_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'key',
          'options': _vm.getFormsOptions,
          'multiple': false,
          'validation': 'required',
          'prefix': 'form_answer_button_',
          'clearable': false,
        },
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }