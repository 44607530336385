import { RootState } from '@/store/state'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'

export default {
	hasForms: (state: RootState, getters) => {
		if (state.formsState.forms === null) {
			return getters.getMenuOptionValue(MenuOptionsKeys.Forms)
		}

		return state.formsState.forms && state.formsState.forms.length > 0
	},
	formsSelectOptions(state: RootState): Array<SelectOptionData> | null {
		if (state.formsState.forms) {
			return state.formsState.forms.map(form => ({
				label: form.name,
				value: form.key,
			}))
		}

		return []
	}
}
