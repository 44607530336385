import BaseApi from '@/includes/services/BaseApi'
import { OutputData } from "@editorjs/editorjs"

export class ArticleService {

    static createArticle(type: string, body: { board_key: string, title: string, article: any }): Promise<any> {
        return BaseApi.sendRequest(type, 'CreateArticle', body)
    }

    static deleteArticle(type: string, body: { board_key: string, key: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'DeleteArticle', body)
    }

    static getArticle(type: string, body: { board_key: string, key: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'GetArticle', body)
    }

    static updateArticle(type: string, body: { board_key: string, key: string, title: string, article: OutputData }): Promise<any> {
        return BaseApi.sendRequest(type, 'UpdateArticle', body)
    }

    static getArticlesInfo(type: string, body: { board_key: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'GetArticlesInfo', body)
    }

    static getArticleRequest(type: string, body: {id: string}): Promise<any> {
        return BaseApi.sendRequest(type, 'GetArticleRequest', body)
    }
}