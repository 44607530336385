





















import { Label } from ".";

import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, VModel, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    SelectInput
  }
})
export default class LabelSetting extends Mixins(ModelSetter) {
  @VModel() model!: Label
}
