import buttonView from './RedirectButton.vue'
import setupView from './RedirectButtonSetupView.vue'
import BaseButtonBuilder
    , { BaseButtonViewData } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import { BaseButton, ButtonTypes } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { BaseFlowButtonWithOutput } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/BaseFlowButtonWithOutput'
import i18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export default class RedirectButtonBuilder extends BaseButtonBuilder {
    type = ButtonTypes.RedirectButton
    titleKey = ButtonTypes.RedirectButton

    setupView = setupView
    buttonView = buttonView

    data = new RedirectButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

    constructor(i18n: i18n) {
        super(i18n)
    }

    icon = '🔗'

    create(): BaseButton {
        return plainToInstance(RedirectButton, {
            ...this.data,
            type: ButtonTypes.RedirectButton,
            guid: Guid.create().toString(),
        })
    }

    getData(button?: RedirectButton): RedirectButtonData {
        return new RedirectButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
    }
}

export class RedirectButtonData implements BaseButtonViewData {
    text = ''
    url = ''

    constructor(data: RedirectButton | string) {
        if(data instanceof RedirectButton || typeof data === 'object') {
            const { text, url } = data

            this.text = text
            this.url = url
        } else {
            this.text = data
        }
    }
}

export class RedirectButton extends BaseFlowButtonWithOutput {
    declare type: ButtonTypes.RedirectButton
    url!: string
}
