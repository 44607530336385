var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-button-setup-view"},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'validation': 'required',
      },
    }}}),_c('editor-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'message',
        'prefix': 'popup_button_',
        'validation': 'required',
        'counterMax': 200,
        'disabledPanelElements': [
          'bold',
          'italic',
          'strike',
          'underline',
          'code',
          'tgpre',
          'link',
          'tgspoiler',
          'clean'
        ],
        trimEnd: true
      },
    }}}),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'delay',
        'prefix': 'popup_button_',
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }