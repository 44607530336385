import { AccessorCondition } from "../AccessorCondition";
import { AccessorTypesEnum } from "../types";

export class ChannelMember extends AccessorCondition {
  type: AccessorTypesEnum = AccessorTypesEnum.ChannelMember;

  targets!: Array<number>

  validate(): Promise<boolean> {
    return Promise.resolve(!!this.targets.length);
  }
}
