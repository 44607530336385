import { ArticleService } from '@/includes/services/ArticleService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { RootState } from "@/store/state";

import { Commit, Dispatch } from 'vuex'
import { OutputData } from '@editorjs/editorjs'

export default {
    requestArticles({ commit, state }: { commit: Commit, state: RootState }): Promise<Array<{ key: string, title: string, article: OutputData }>> {
        return new Promise((resolve, reject) => {
            ArticleService.getArticlesInfo('tg', { board_key: state.boardsState.activeBoard!.board })
                .then((data) => {
                    commit('SET_ARTICLES_TO_STORE', data.items)
                    resolve(data.items)
                })
                .catch((error:any) => {
                    reject()
                    errorNotification(error)
                })
        })
    },
    removeArticle: ({ commit, state }: { commit: Commit, state: RootState }, key:string) => {
        return ArticleService.deleteArticle('tg', { board_key: state.boardsState.activeBoard!.board, key })
            .then((res) => {
                commit('REMOVE_ARTICLE', res.key)

                successNotification()
            })
            .catch(errorNotification)
    },
    addArticle: ({ commit, state }: { commit: Commit, state: RootState }, payload: { title: string, article: any }) => {
        return ArticleService.createArticle('tg', {
            board_key: state.boardsState.activeBoard!.board,
            title: payload.title,
            article: payload.article
        })
            .then((res) => {
                commit('ADD_ARTICLE', res)

                successNotification()

                return res
            })
            .catch(errorNotification)
    },
    updateArticle: ({ commit, state, dispatch }: { commit: Commit, dispatch:Dispatch, state: RootState }, payload: { key:string, title: string, article: any }) => {
        return ArticleService.updateArticle('tg', {
            board_key: state.boardsState.activeBoard!.board,
            key: payload.key,
            title: payload.title,
            article: payload.article
        })
            .then((res) => {
                if (state.articlesState.articles === null) {
                    return dispatch('requestArticles')
                        .then(() => commit('UPDATE_ARTICLE_BY_KEY', res))
                } else {
                    commit('UPDATE_ARTICLE_BY_KEY', res)
                }

                successNotification()
            })
            .catch(errorNotification)
    }
}
