import { cloneDeep, snakeCase } from "lodash";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";
import { AccessorCondition } from "./AccessorCondition";

export abstract class AccessorConditionBuilder {
  abstract model: AccessorCondition

  abstract icon: string;

  abstract view: { setting: VueComponent; preview: VueComponent }

  updateModel(model: AccessorCondition) {
    this.model = cloneDeep(model)
  }

  titleKey() {
    if (this.model) {
      return `punish_action_type_${ snakeCase(this.model.type) }`
    }

    return ''
  }

  helpMessageKey() {
    if (this.model) {
      return `${ this.model.type }_help_message`
    }

    return ''
  }
}
