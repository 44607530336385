import BaseApi from '@/includes/services/BaseApi'
import {
    InviteLinkStatReq,
    InviteLinksWithStat,
    InviteLinkToCreate,
    InviteLinkToEdit,
    InviteLinkWithKey
} from '@/includes/logic/InviteLinks/types'

export default class InviteLinksApi {
    static async getInviteLinks(type: string, body: { board_key: string, target_id: number }): Promise<{ links: Array<InviteLinkWithKey> }> {
        return BaseApi.sendRequest(type, 'getinvitelinks', body)
    }

    static async createInviteLink(type: string, body: { board_key: string, target_id: number } & InviteLinkToCreate): Promise<{ link: InviteLinkWithKey }> {
        return BaseApi.sendRequest(type, 'createinvitelink', body)
    }

    static async setInviteLink(type: string, body: { board_key: string, link_key: string } & InviteLinkToEdit): Promise<{ link: InviteLinkWithKey }> {
        return BaseApi.sendRequest(type, 'setinvitelink', body)
    }

    static async deleteInviteLink(type: string, body: { board_key:string, link_key:string }): Promise<void> {
        return BaseApi.sendRequest(type, 'deleteinvitelink', body)
    }

    static async getInviteLinksStat(type: string, body: { board_key: string, target_id: number } & InviteLinkStatReq): Promise<{links: InviteLinksWithStat}> {
        return BaseApi.sendRequest(type, 'getinvitelinksstat', body)
    }
}