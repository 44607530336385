






















































































































import { HiddenCountedMessageButton } from '.'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver,
  },
})
export default class HiddenCountedMessageButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: HiddenCountedMessageButton
}
