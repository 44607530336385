import { ChannelsState, channelsState } from './channel/state'
import { StatisticsState, statisticsState } from './statistics/state'
import { ArticlesState, articlesState } from './articles/state'
import { FlowsState, flowsState } from "./flow/state";
import { BoardsState, boardsState } from './boards/state'
import { FormsState, formsState } from "./forms/state"
import { NotificationsState, notificationsState } from '@/store/notifications/state'
import { ShopState, shopState } from "@/store/shop/state"
import { DraftsState, draftsState } from "@/store/drafts/state"
import { MenuOptionsState, menuOptionsState } from './menuOptions/state'
import { ChannelInfo } from "@/includes/types/Board.types";
import { InviteLinksState, inviteLinksState } from './InviteLinks/state'

import { AdminTemplateModuleStore } from 'piramis-base-components/src/plugins/AdminTemplate/store/StoreTypes'

export type RootState = {
    tempTrigger: any
    targetsWithoutLinkedGroup: Array<ChannelInfo> | null
    validateCommentButtonHasGroupFields: boolean
    testStateCounter: number
    notificationsState: NotificationsState
    boardsState: BoardsState
    channelsState: ChannelsState
    articlesState: ArticlesState
    flowsState: FlowsState
    statisticsState: StatisticsState
    metaModule: any
    AdminTemplate: AdminTemplateModuleStore
    shopState: ShopState
    formsState: FormsState
    draftsState: DraftsState
    menuOptionsState: MenuOptionsState
    inviteLinksState: InviteLinksState
}

const state: RootState = {
    tempTrigger: null,
    targetsWithoutLinkedGroup: null,
    validateCommentButtonHasGroupFields: false,
    notificationsState,
    testStateCounter: 0,
    // boards
    boardsState,
    // current channel
    channelsState,
    // articles
    articlesState,
    flowsState,
    statisticsState,
    metaModule: {},
    AdminTemplate: {} as AdminTemplateModuleStore,
    shopState,
    formsState,
    draftsState,
    menuOptionsState,
    inviteLinksState,
}

export default state
