var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('div',{staticClass:"limited-subscription-link"},[(!_vm.getHasChannelsContentSubscription())?_c('a-alert',{staticClass:"my-2",attrs:{"type":"error","show-icon":""}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('limited_subscription_link_error'))},slot:"message"})]):_vm._e(),_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'limited_subscription_link_button_',
          'validation': 'required',
          'saveFieldKey': 'text_limited_subscription_button',
          'disabled': !_vm.getHasChannelsContentSubscription(),
        },
      }}}),_c('pi-tabs',{staticClass:"justify-center md:justify-start mb-5",attrs:{"tabs":_vm.tabs,"multiline":"","current":_vm.currentTab},on:{"update:current":function($event){_vm.currentTab=$event}}}),_c('a-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 1),expression:"currentTab === 1"}],attrs:{"size":"small"}},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            model: _vm.model,
            'key': 'subscribedTargetType',
            'prefix': 'limited_subscription_link_button_',
            'validation': 'required',
            'clearable': false,
            'options': _vm.hiddenTargetOptions,
            'disabled': !_vm.getHasChannelsContentSubscription(),
          },
        }},on:{"input":_vm.onSubscribedTargetTypeChange}}),_c('hidden-target-types-views',{attrs:{"model-key":'subscribedTargetValue',"hidden-target-type":_vm.model.subscribedTargetType,"get-articles-fn":_vm.getArticlesFn,"disabled":!_vm.getHasChannelsContentSubscription()},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('a-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 2),expression:"currentTab === 2"}],attrs:{"size":"small"}},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            model: _vm.model,
            'key': 'unsubscribedTargetType',
            'prefix': 'limited_subscription_link_button_',
            'validation': 'required',
            'clearable': false,
            'options': _vm.hiddenTargetOptions,
            'disabled': !_vm.getHasChannelsContentSubscription(),
          },
        }},on:{"input":_vm.onUnsubscribedTargetTypeChange}}),_c('hidden-target-types-views',{attrs:{"model-key":'unsubscribedTargetValue',"hidden-target-type":_vm.model.unsubscribedTargetType,"get-articles-fn":_vm.getArticlesFn,"disabled":!_vm.getHasChannelsContentSubscription()},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }