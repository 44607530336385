import { IntegrationRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/RequestTypes/types";
import { ChangePointsRequest } from "./ChangePointsRequest";
import { IntegrationRequestBuilder } from "../IntegrationRequest/IntegrationRequestBuilder";

import { plainToInstance } from "class-transformer";

export const ChangePointsRequestBuilder = new class ChangePointsRequestBuilder extends IntegrationRequestBuilder{
    type: IntegrationRequestTypeEnum = IntegrationRequestTypeEnum.ChangePoints;

    settings = () => import('../components/ChangeActivitySettings.vue')
    preview = () => import('../components/ChangeActivityPreview.vue')

    getModel() {
        return plainToInstance(ChangePointsRequest, {
            type: IntegrationRequestTypeEnum.ChangePoints,
            token: '',
            value: 0,
            lower_limit: 0,
            upper_limit: 0,
            reason: '',
        })
    }
}
