import axios from 'axios'
import { isEmpty } from 'lodash'
import { RootState } from '@/store/state'
import { Commit } from 'vuex'

export default {
    async getStatisticsLocales({ state, commit }: { state: RootState, commit: Commit }): Promise<boolean> {
        if (isEmpty(state.statisticsState.locales)) {
            try {
                let en = await axios
                    .get('https://cdn.jsdelivr.net/npm/apexcharts/dist/locales/en.json')

                let ru = await axios
                    .get('https://cdn.jsdelivr.net/npm/apexcharts/dist/locales/ru.json')

                const locales = [ en.data, ru.data ]

                commit('updateStatisticsLocales', locales)

                return true
            } catch (err) {
                console.error(err)
                return false
            }
        } else {
            return true
        }
    },
}
