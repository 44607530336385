import { RootState } from '@/store/state'
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'

export default {
    hasDrafts: (state: RootState, getters) => {
        if (state.draftsState.drafts === null) {
            return getters.getMenuOptionValue(MenuOptionsKeys.Saved_posts)
        }

        return state.draftsState.drafts && state.draftsState.drafts.length > 0
    },
}