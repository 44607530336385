import buttonView from './HiddenSubscriberLinkButton.vue'
import setupView from './HiddenSubscriberLinkButtonSetupView.vue'
import { HiddenTargetType } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import Button, { ButtonStruct, BaseButtonProps } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

type HiddenSubscriberLinkButtonDataType = {
  text: string
  subscribedTargetType: HiddenTargetType
  subscribedTargetValue: string
  unsubscribedTargetType: HiddenTargetType
  unsubscribedTargetValue: string
  notEnoughSubscribedTargetType: HiddenTargetType
  notEnoughSubscribedTargetValue: string
  daysCount: number
}

export default class HiddenSubscriberLinkButton extends Button {
  name = 'HiddenSubscriberLinkButton'

  setupView = setupView
  buttonView = buttonView

  data: HiddenSubscriberLinkButtonDataType = this.getRawData()

  structBuilder = HiddenSubscriberLinkButtonStruct

  icon = '🔔'

  constructor(props: BaseButtonProps<{ getArticlesFn: () => Promise<Array<SelectOptionData>> }>) {
    super(props)
  }

  getRawData(): HiddenSubscriberLinkButtonDataType {
    return {
      text: '',
      subscribedTargetType: HiddenTargetType.Message,
      subscribedTargetValue: this.$i18n.t('initial_text_message_editor_hidden_subscriber_subscribed').toString(),
      unsubscribedTargetType: HiddenTargetType.Message,
      unsubscribedTargetValue: this.$i18n.t('initial_text_message_editor_hidden_subscriber_unsubscribed').toString(),
      notEnoughSubscribedTargetType: HiddenTargetType.Message,
      notEnoughSubscribedTargetValue: this.$i18n.t('initial_text_message_editor_hidden_subscriber_not_enough_subscribed').toString(),
      daysCount: 31,
    }
  }
}

export class HiddenSubscriberLinkButtonStruct extends ButtonStruct {
  subscribedTargetType: HiddenTargetType
  subscribedTargetValue: string
  unsubscribedTargetType: HiddenTargetType
  unsubscribedTargetValue: string
  notEnoughSubscribedTargetType: HiddenTargetType
  notEnoughSubscribedTargetValue: string
  daysCount: number

  constructor(struct: HiddenSubscriberLinkButtonStruct) {
    super(struct)

    const {
      subscribedTargetType,
      subscribedTargetValue,
      unsubscribedTargetType,
      unsubscribedTargetValue,
      notEnoughSubscribedTargetType,
      notEnoughSubscribedTargetValue,
      daysCount,
    } = struct

    this.subscribedTargetType = subscribedTargetType
    this.subscribedTargetValue = subscribedTargetValue
    this.unsubscribedTargetType = unsubscribedTargetType
    this.unsubscribedTargetValue = unsubscribedTargetValue
    this.notEnoughSubscribedTargetType = notEnoughSubscribedTargetType
    this.notEnoughSubscribedTargetValue = notEnoughSubscribedTargetValue
    this.daysCount = daysCount
  }

}
