import buttonView from './CommentsButton.vue'
import setupView from './CommentsButtonSetupView.vue'
import Button, { ButtonStruct, BaseButtonProps } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'

type CommentsButtonDataType = {
  text: string
  emptyText: string
}

export default class CommentsButton extends Button {
  name = 'CommentsButton'

  setupView = setupView
  buttonView = buttonView

  data: CommentsButtonDataType = this.getRawData()

  structBuilder = CommentsButtonStruct

  icon = '🗣️'

  constructor(props: BaseButtonProps<{ botName: string }>) {
    super(props)
  }

  getRawData(): CommentsButtonDataType {
    return {
      text: this.$i18n.t('initial_text_message_editor_comments_button').toString(),
      emptyText: ''
    }
  }
}

export class CommentsButtonStruct extends ButtonStruct {
  emptyText: string

  constructor(struct: CommentsButtonStruct) {
    super(struct)

    this.emptyText = struct.emptyText
  }
}
