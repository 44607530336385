




























import { ChannelMember } from ".";
import { warningNotification } from "@/includes/services/NotificationService";

import PiDropdownInput from 'piramis-base-components/src/components/PiDropdownInput.vue';
import TargetCard from 'piramis-base-components/src/components/TargetCard/TargetCard.vue';
import ChannelsInfoHelper from "piramis-base-components/src/components/Flow/new/logic/Helpers/ChannelsInfoHelper";
import CustomEditor from "piramis-base-components/src/components/Flow/new/logic/Editor/CustomEditor";
import { ErrorChannelInfo, SuccessChannelInfo } from "piramis-base-components/src/models/CheckChannels/CheckChannels";
import { TargetCardInfo } from "piramis-base-components/src/components/TargetCard/types";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Vue, Component, VModel, Prop } from 'vue-property-decorator';

@Component({
  components: {
    TargetCard,
    PiDropdownInput,
    ConfigField
  }
})
export default class ChannelMemberSetting extends Vue {
  @VModel() model!: ChannelMember

  @Prop() editor!: CustomEditor

  addTarget(target: string) {
    if (target.trim()) {
      if (this.model.targets.indexOf(+target) === -1) {
        this.editor.getChannelsInfo([ target.toString() ])
          .then(({ data }) => {
            const [ target ] = data

            if (target instanceof SuccessChannelInfo) {
              this.model.targets.push(target.info.id)
            } else {
              warningNotification(this.$t('channel_not_found').toString())
            }
          })
      } else {
        warningNotification(this.$t('notification_warn_target_exists').toString())
      }
    }

  }

  get beautifiedTargets() {
    return this.model.targets.map(id => {
      const target = ChannelsInfoHelper.GetChannel(id.toString())

      if (target) {
        const isSuccessChannelInfo = target instanceof SuccessChannelInfo

        return {
          type: isSuccessChannelInfo ? target?.info?.type?.toLowerCase() : null,
          id: isSuccessChannelInfo ? target?.info?.id : target!.value,
          title: isSuccessChannelInfo ? target?.info?.title : null,
          login: isSuccessChannelInfo ? (target?.info?.login ?? null) : null,
          error: target instanceof ErrorChannelInfo ? target.error : null,
        }
      } else {
        return {
          type: null,
          id: id,
          title: null,
          login: null,
          error: null,
        }
      }
    })
  }

  deleteTarget(target: TargetCardInfo) {
    this.model.targets = this.model.targets.filter(t => t !== target.id)
  }
}
