import { PostCommentsStateTypes, Style, WatermarkPosition, WatermarkType } from '@/includes/types/Post.types'

export const defaultStyleModel:Style = {
    compress_level: null,
    protect_content: false,
    disable_notify: false,
    disable_link_preview: false,
    pin: false,
    data: {
        color: null,
        post_price: 0,
        post_comments_state: PostCommentsStateTypes.None,
        targets: []
    },
    watermark_type: WatermarkType.None,
    watermark_position: WatermarkPosition.Center,
    watermark_data: null,
    prefix_message: [ {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
        protect_content: false,
        remove_after: 0,
        send_after: 0
    } ],
    postfix_message: [ {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
        protect_content: false,
        remove_after: 0,
        send_after: 0
    } ],
    actions: [],
}