import { RootState } from '@/store/state'
import { InviteLinkWithKey } from '@/includes/logic/InviteLinks/types'

export default {
	SET_INVITE_LINKS_TO_STORE(state: RootState, links: Array<InviteLinkWithKey>) {
		state.inviteLinksState.links = links
	},
	DELETE_INVITE_LINK(state: RootState, key: string) {
		if (state.inviteLinksState.links) {
			const index = state.inviteLinksState.links.findIndex((link) => link.key === key)

			if (index !== -1) {
				state.inviteLinksState.links.splice(index, 1)
			}
		}
	},
	ADD_INVITE_LINK(state: RootState, link: InviteLinkWithKey) {
		state.inviteLinksState.links!.push(link)
	},
	UPDATE_INVITE_LINK(state: RootState, link: InviteLinkWithKey) {
		const index = state.inviteLinksState.links!.findIndex((l) => l.key === link.key)

		if (index !== -1) {
			state.inviteLinksState.links!.splice(index, 1, link)
		}
	},
	CLEAR_INVITE_LINKS(state: RootState) {
		state.inviteLinksState.links = null
	}
}
