import BaseApi from '@/includes/services/BaseApi'
import { UtmSource } from '@/includes/types/MetaData.types'
import { MenuOptions } from '@/includes/types/MenuOptions.types'

export default class MetaDataService {
    static getUtmSources(type:string, body:{board_key:string}):Promise<{items: Array<UtmSource>}> {
        return BaseApi.sendRequest(type, 'getutmsources', body)
    }

    static getMenuOptions(type:string, body:{board_key:string}):Promise<MenuOptions> {
        return BaseApi.sendRequest(type, 'getmenuoptions', body)
    }
}