










































































import { HiddenMessageButton } from './index'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import TextareaInput from 'piramis-base-components/src/components/Pi/fields/TextareaInput/TextareaInput.vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    TextareaInput
  },
})
export default class HiddenMessageButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: HiddenMessageButton
}
