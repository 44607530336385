import store from '@/store/store'
import { BoardPermissions } from '@/includes/types/Board.types'
import i18n from '@/i18n/i18n'

const hasChangeConfigPermission = (permissions?: Array<BoardPermissions> | null): boolean => {
    if (permissions) {
        return permissions.includes('ChangeConfig')
    } else {
        const activeBoard = store.state.boardsState.activeBoard

        if (activeBoard) {
            return activeBoard.permissions.includes('ChangeConfig')
        }

        return false
    }
}

const canCreatePost = (permissions?: Array<BoardPermissions> | null): boolean => {
    if (permissions) {
        return permissions.includes('CreatePost')
    } else {
        const activeBoard = store.state.boardsState.activeBoard

        if (activeBoard) {
            return activeBoard.permissions.includes('CreatePost')
        }

        return false
    }
}

const canEditAndDeletePost = (permissions?: Array<BoardPermissions> | null): boolean => {
    if (permissions) {
        return permissions.includes('EditPosts')
            && permissions.includes('ViewOtherPosts')
            && permissions.includes('CreatePost')
    } else {
        const activeBoard = store.state.boardsState.activeBoard

        if (activeBoard) {
            return activeBoard.permissions.includes('EditPosts')
                && activeBoard.permissions.includes('ViewOtherPosts')
                && activeBoard.permissions.includes('CreatePost')
        }
    }

    return false
}

const canViewOtherPosts = (permissions?: Array<BoardPermissions> | null): boolean => {
    if (permissions) {
        return permissions.includes('ViewOtherPosts')
    } else {
        const activeBoard = store.state.boardsState.activeBoard

        if (activeBoard) {
            return activeBoard.permissions.includes('ViewOtherPosts')
        }
    }

    return false
}

const canPostNow = (permissions?: Array<BoardPermissions> | null): boolean => {
    if (permissions) {
        return permissions.includes('CreatePost')
            && permissions.includes('IgnoreHold')
    } else {
        const activeBoard = store.state.boardsState.activeBoard

        if (activeBoard) {
            return  activeBoard.permissions.includes('CreatePost')
                && activeBoard.permissions.includes('IgnoreHold')
        }
    }

    return false
}

const canAccessPublished = (permissions?: Array<BoardPermissions> | null): boolean => {
    if (permissions) {
        return permissions.includes('CreatePost')
            && permissions.includes('AccessPublishedPosts')
            && permissions.includes('ViewOtherPosts')
    } else {
        const activeBoard = store.state.boardsState.activeBoard

        if (activeBoard) {
            return activeBoard.permissions.includes('CreatePost')
                && activeBoard.permissions.includes('AccessPublishedPosts')
                && activeBoard.permissions.includes('ViewOtherPosts')
        }
    }

    return false
}

const createPostButtonTitle = (permissions: Array<BoardPermissions>): string => {
    if (canCreatePost(permissions)) {
        return i18n.t('create_new_post_button').toString()
    }
    return i18n.t('suggest_post_button').toString()
}

export {
    hasChangeConfigPermission,
    canCreatePost,
    canEditAndDeletePost,
    canViewOtherPosts,
    createPostButtonTitle,
    canPostNow,
    canAccessPublished
}