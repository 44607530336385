























































































































import Editor from 'piramis-base-components/src/components/Editor/Editor.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'
import { HiddenMessageButtonStruct } from '@/components/ProjectButtons/HiddenMessageButton/index'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

@Component({
  components: {
    ValidationObserver,
    Editor,
    ValidationProvider
  },
})
export default class HiddenMessageButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: HiddenMessageButtonStruct

  created() {
    if (this.model.notEnoughSubscribedMessage === null) {
      this.model.notEnoughSubscribedMessage = ''
    }
  }
}
