import buttonView from './SimpleHiddenMessageButton.vue'
import setupView from './SimpleHiddenMessageButtonSetupView.vue'
import Button, { ButtonStruct } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import { cloneDeep } from 'lodash'
import { Guid } from 'guid-typescript'

export type SimpleHiddenMessageButtonDataType = {
  text: string
  daysCount: 0,
  subscribedMessage: string
  notEnoughSubscribedMessage: string
  unsubscribedMessage: string
}

export default class SimpleHiddenMessageButton extends Button {
  name = 'SimpleHiddenMessageButton'

  setupView = setupView
  buttonView = buttonView

  data: SimpleHiddenMessageButtonDataType = this.getRawData()

  structBuilder = SimpleHiddenMessageButtonStruct

  icon = '🔔'

  getRawData(): SimpleHiddenMessageButtonDataType {
    return {
      text: '',
      daysCount: 0,
      subscribedMessage: this.$i18n.t('initial_text_message_editor_simple_hidden_message_subscriber').toString(),
      notEnoughSubscribedMessage: '',
      unsubscribedMessage: this.$i18n.t('initial_text_message_editor_simple_hidden_message_unsubscriber').toString(),
    }
  }

  create(): SimpleHiddenMessageButtonStruct {
    const struct = new SimpleHiddenMessageButtonStruct({
      ...cloneDeep(this.data),
      type: 'HiddenMessageButton',
      guid: Guid.create().toString(),
    })
    this.refreshData()

    return struct
  }
}

export class SimpleHiddenMessageButtonStruct extends ButtonStruct {
  daysCount: number
  subscribedMessage: string
  notEnoughSubscribedMessage: string
  unsubscribedMessage: string

  constructor(struct: SimpleHiddenMessageButtonStruct) {
    super(struct)

    const { daysCount, subscribedMessage, notEnoughSubscribedMessage, unsubscribedMessage } = struct

    this.daysCount = daysCount
    this.subscribedMessage = subscribedMessage
    this.notEnoughSubscribedMessage = notEnoughSubscribedMessage
    this.unsubscribedMessage = unsubscribedMessage
  }

}
