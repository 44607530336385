import { render, staticRenderFns } from "./LabelPreview.vue?vue&type=template&id=0f8905be&scoped=true&"
import script from "./LabelPreview.vue?vue&type=script&lang=ts&"
export * from "./LabelPreview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f8905be",
  null
  
)

export default component.exports