import { RootState } from "@/store/state";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { SelectOption } from "piramis-base-components/src/logic/types";
import { Style } from "@/includes/types/Post.types";
import { defaultStyleModel } from "@/includes/logic/Style/constant";

export default {
    hasBoardStyles: (state: RootState, getters) => {
        if (getters.isBoardSet) {
            return getters.activeBoard.config.styles && Object.keys(getters.activeBoard.config.styles).length
        }

        return false
    },
    styleOptionsNames: (state: RootState): Array<SelectOptionData> => {
        const board = state.boardsState.activeBoard

        if (board) {
            const styleNames = Object.keys(board.config?.styles ?? [])

            return styleNames.reduce<Array<SelectOption>>((acc, key) => {
                acc.push({ label: key, value: key })

                return acc
            }, [ { label: '-', value: '' } ])
        }

        return []
    },
    styleOptionsIds: (state: RootState) => {
        if (state.boardsState.activeBoard) {
            const styleEntries = Object.entries(state.boardsState.activeBoard.config?.styles ?? [])

            return (styleEntries.length ? styleEntries : []).reduce<Array<SelectOption>>((acc, [ key, value ]) => {
                acc.push({ label: key, value: (value as Style).id })

                return acc
            }, [])
        }

        return []

    },
    findStyle: (state: RootState) => (id: number) => {
        if (state.boardsState.activeBoard) {
            return Object.values(state.boardsState.activeBoard.config?.styles ?? {}).find(s => s.id === id) ?? defaultStyleModel
        }
    },
}