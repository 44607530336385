import BaseApi from "@/includes/services/BaseApi";
import { BoardConfig } from '@/includes/types/Board.types'

export class LabelsService {
	static createLabel(type: string, body: { board_key: string, title: string }): Promise<{ index: number, labels: BoardConfig['labels'] }> {
		return BaseApi.sendRequest(type, 'createlabel', body)
	}

	static deleteLabel(type: string, body: { board_key: string, index: number }): Promise<{ labels: BoardConfig['labels'] }> {
		return BaseApi.sendRequest(type, 'deletelabel', body)
	}

	static renameLabel(type: string, body: { board_key: string, index: number, title: string }): Promise<{ labels: BoardConfig['labels'] }> {
		return BaseApi.sendRequest(type, 'renamelabel', body)
	}
}