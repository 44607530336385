import { IntegrationRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/RequestTypes/types";
import { IntegrationRequest } from "./IntegrationRequest";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export abstract class IntegrationRequestBuilder {
    abstract type: IntegrationRequestTypeEnum

    abstract getModel(): IntegrationRequest

    abstract settings: VueComponent
    abstract preview: VueComponent
}