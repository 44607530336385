


















































































































































import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import HiddenTargetTypesViews
  from '@/components/ProjectButtons/includes/components/HiddenTargetTypesViews/HiddenTargetTypesViews.vue'
import { hiddenTargetTypeOptions } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import { HiddenCountedLinkButtonStruct } from '@/components/ProjectButtons/HiddenCountedLinkButton/index'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

enum Tabs {
  before,
  after,
}

@Component({
  data() {
    return {
      Tabs,
    }
  },
  components: {
    HiddenTargetTypesViews,
    ValidationObserver,
  },
})
export default class HiddenCountedLinkButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: HiddenCountedLinkButtonStruct

  @Prop({ 'type': Function }) readonly getArticlesFn!: () => Promise<Array<SelectOptionData>>

  @Watch('model.targetType')
  onAfterTargetTypeChange(): void {
    this.model.targetValue = ''
  }

  @Watch('model.afterTargetType')
  onTargetTypeChange(): void {
    this.model.afterTargetValue = ''
  }

  activeTab: Tabs = Tabs.before

  async hiddenTargetOptions(): Promise<Array<SelectOptionData>> {
    return hiddenTargetTypeOptions().map((o) => {
      return {
        label: this.$t(o.label).toString(),
        value: o.value
      }
    })
  }
}
