import i18n from '@/i18n/i18n'
import { BaseItemViewAction } from './types/BaseItemViewAction'
import store from '@/store/store'

import TemplateContext from 'piramis-base-components/src/plugins/AdminTemplate/includes/TemplateContext'
import { BreadcrumbStruct } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem'

import { Route } from 'vue-router'

export function actionTypeBreadcrumb(ctx: TemplateContext): string {
    let label = ''

    if (ctx?.route.params.actionType === BaseItemViewAction.New) label = i18n.t('page_action_type_new').toString()
    if (ctx?.route.params.actionType === BaseItemViewAction.Edit) label = i18n.t('page_action_type_edit').toString()
    if (ctx?.route.params.actionType === BaseItemViewAction.Watch) label = i18n.t('page_action_type_watch').toString()
    if (ctx?.route.params.actionType === BaseItemViewAction.Copy) label = i18n.t('page_action_type_new').toString()

    return label
}

export function baseBoardBreadcrumbs(ctx: Route): Array<BreadcrumbStruct> {
    const board = store.state.boardsState.activeBoard

    if (board) {
        return [
            {
                iconName: 'icon-message-circle',
                routeName: 'Boards',
                label: ''
            },
            {
                image: () => board.avatar,
                iconName: 'calendar-icon',
                label: () => board.title,
                children: [
                    {
                        label: i18n.t('menu_common_settings_title').toString(),
                        routeName: 'Common_settings',
                        iconName: 'icon-settings'
                    },
                    {
                        label: i18n.t('menu_offerslist_title').toString(),
                        routeName: 'OffersList',
                        iconName: 'icon-dollar-sign'
                    },
                    {
                        label: i18n.t('menu_bot_feedback_title').toString(),
                        routeName: 'Bot_feedback',
                        iconName: 'icon-message-square'
                    },
                    {
                        label: i18n.t('menu_channels_title').toString(),
                        routeName: 'Channels',
                        iconName: 'icon-radio'
                    },
                    {
                        label: i18n.t('menu_posts_planner_title').toString(),
                        routeName: 'posts_planner',
                        iconName: 'icon-calendar'
                    },
                    {
                        label: i18n.t('menu_distributions_title').toString(),
                        routeName: 'Distributions',
                        iconName: 'icon-rss'
                    },
                    {
                        label: i18n.t('menu_users_options_title').toString(),
                        routeName: 'Users_options',
                        iconName: 'icon-users'
                    },
                    {
                        label: i18n.t('menu_flows_list_title').toString(),
                        routeName: 'Flows_List',
                        iconName: 'icon-git-pull-request'
                    },
                    {
                        label: i18n.t('menu_bot_properties_title').toString(),
                        routeName: 'Bot_Properties',
                        iconName: 'icon-archive'
                    },

                    {
                        label: i18n.t('menu_shop_title').toString(),
                        routeName: 'Shop',
                        iconName: 'icon-shopping-cart'
                    },
                    {
                        label: i18n.t('menu_drafts_list_title').toString(),
                        routeName: 'Drafts_list',
                        iconName: 'icon-folder'
                    },
                    {
                        label: i18n.t('menu_styles_title').toString(),
                        routeName: 'Styles',
                        iconName: 'icon-layers'
                    },
                    {
                        label: i18n.t('menu_forms_title').toString(),
                        routeName: 'Forms',
                        iconName: 'icon-edit'
                    },
                    {
                        label: i18n.t('menu_articles_list_title').toString(),
                        routeName: 'articles_list',
                        iconName: 'icon-file-text'
                    },
                ]
            },
        ]
    }

    return []
}

export function baseChannelBreadcrumbs(ctx: Route): Array<BreadcrumbStruct> {
    const channel = store.state.channelsState.activeChannel

    if (channel) {
        return [
            {
                routeName: 'Channel_main_settings',
                image: () => channel.avatar,
                label: () => channel.title
            },
        ]
    }

    return []
}