import { RootState } from '../state'
import { groupBy } from 'lodash'

export default {
    groupedNotifications: (state: RootState) => {
        const groups = {
            suggest_posts: {}
        }

        groups.suggest_posts = groupBy(state.notificationsState?.suggest_posts, (post) => post.board_key)

        return groups
    }
}