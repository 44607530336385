var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[(!_vm.targetsWithoutLinkedGroup)?_c('a-alert',{staticClass:"my-2",attrs:{"message":_vm.$t('flow_subscription_check_node_not_set_channel_text'),"show-icon":"","type":"warning"}}):[(_vm.targetsWithoutLinkedGroupExists)?_c('a-alert',{staticClass:"my-2",attrs:{"type":"error","show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('group_not_connected_alert', { channels: _vm.targetsWithoutLinkedGroupTitles, bot: _vm.botName }))},slot:"message"})]):_vm._e()],_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'prefix': 'comments_button_',
        'validation': 'required',
        'disabled': _vm.isFieldDisabled,
      },
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'emptyText',
        'prefix': 'comments_button_',
        'validation': 'required',
        'disabled': _vm.isFieldDisabled,
      },
    }}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }