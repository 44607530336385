import { ChangePointsRequest } from "./ChangePointsRequest/ChangePointsRequest";
import { ChangeReputationRequest } from "./ChangeReputationRequest/ChangeReputationRequest";
import { SendMessageRequest } from "./SendMessageRequest/SendMessageRequest";
import { IntegrationRequestTypeEnum } from "./types";

export const transformerSubtypes = [
  { name: IntegrationRequestTypeEnum.SendMessage, value: SendMessageRequest },
  { name: IntegrationRequestTypeEnum.ChangeReputation, value: ChangeReputationRequest },
  { name: IntegrationRequestTypeEnum.ChangePoints, value: ChangePointsRequest },
]
