import CommentsButton from '@/components/ProjectButtons/CommentsButton'
import HiddenCountedMessageButton
	from '@/components/ProjectButtons/HiddenCountedMessageButton'
import HiddenMessageButton from '@/components/ProjectButtons/HiddenMessageButton'
import SimpleHiddenMessageButton from '@/components/ProjectButtons/SimpleHiddenMessageButton'
import HiddenSubscriberLinkButton
	from '@/components/ProjectButtons/HiddenSubscriberLinkButton'
import HiddenCountedLinkButton from '@/components/ProjectButtons/HiddenCountedLinkButton'
import ShareTextButton from '@/components/ProjectButtons/ShareTextButton'
import RunFlowButton from '@/components/ProjectButtons/RunFlowButton'
import MetaDataService from '@/includes/services/MetaDataService'
import { ForwarMessageActionMode } from '@/includes/types/Post.types'
import FormButton from '@/components/ProjectButtons/FormButton'
import Button
	from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import CaptchaButton from '@/components/ProjectButtons/CaptchaButton'
import CaptchaButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/Captcha'
import RunFlowButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RunFlowButton'
import ShareTextButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/ShareTextButton'
import FormButtonButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/FormButton'
import HiddenMessageButtonBuilder from '@/components/ProjectButtons/New/HiddenMessageButton'
import SimpleHiddenMessageButtonBuilder from '@/components/ProjectButtons/New/SimpleHiddenMessageButton'
import HiddenCountedLinkButtonBuilder from '@/components/ProjectButtons/New/HiddenCountedLinkButton'
import HiddenCountedMessageButtonBuilder from '@/components/ProjectButtons/New/HiddenCountedMessageButton'
import HiddenSubscriberLinkButtonBuilder from '@/components/ProjectButtons/New/HiddenSubscriberLinkButton'
import CommentsButtonBuilder from '@/components/ProjectButtons/New/CommentsButton'

import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { AvailableButtonsTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'
import FingerDownReaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerDownReaction'
import Share
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Share'
import Reaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Reaction'
import PalmsReaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/PalmsReaction'
import FingerUpReaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerUpReaction'
import Link
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Link'
import { MultiMessageEditorWithMediaData } from 'piramis-base-components/src/components/MultiMessageEditorWithMedia/types'
import Text
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text'
import FlowFormButton
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FlowFormButton'
import RunFlowNodeButton
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/RunFlowNodeButton/index'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import LinkButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link/index'
import ShareButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share/index'
import BaseButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import {
	ButtonBuildersGroups
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types'
import ReactionButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Reaction'
import FingerDownReactionButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerDownReaction'
import FingerUpReactionButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerUpReaction'
import PalmReactionButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/PalmReaction'
import TextButtonBuilder
	from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/TextButton/index";

import { Vue, Component } from 'vue-property-decorator'
import { mapActions } from 'vuex'
import { errorNotification } from "@/includes/services/NotificationService";

type moreButtons = { moreSimpleButton?: Array<Button>, moreProButtons?: Array<Button> }
type newMoreButtons = { moreSimpleButton?: Array<BaseButtonBuilder>, moreProButtons?: Array<BaseButtonBuilder> }

@Component({
	data() {
		return {
			StepUnit
		}
	},
	methods: {
		...mapActions([ 'resolveFlowSelectOption' ])
	}
})
export class InputSetups extends Vue {
	resolveFlowSelectOption!: Promise<Array<SelectOption>>

	props = { i18n: this.$i18n }

	getNewDefaultButtons(moreButtons?: newMoreButtons) {
		return [
			new ButtonBuildersGroups(this.$t('field_simple_buttons_title').toString(),[
				new LinkButtonBuilder(this.$i18n),
				new ShareButtonBuilder(this.$i18n),
				new SimpleHiddenMessageButtonBuilder(this.$i18n),
				new ShareTextButtonBuilder(this.$i18n),
				new ReactionButtonBuilder(this.$i18n),
				new PalmReactionButtonBuilder(this.$i18n),
				new FingerUpReactionButtonBuilder(this.$i18n),
				new FingerDownReactionButtonBuilder(this.$i18n),
				...moreButtons && moreButtons.moreSimpleButton ? moreButtons.moreSimpleButton : [],
				new FormButtonButtonBuilder(this.$i18n, this.getFormsSelectOptions),
			]),
			new ButtonBuildersGroups(this.$t('field_pro_buttons_title').toString(),[
				new RunFlowButtonBuilder(this.$i18n, this.resolveFlowSelectOption),
				new CommentsButtonBuilder(this.$i18n, this.$store.state.boardsState.activeBoard?.title),
				new HiddenMessageButtonBuilder(this.$i18n),
				new HiddenCountedMessageButtonBuilder(this.$i18n),
				new HiddenSubscriberLinkButtonBuilder(this.$i18n, this.getArticlesSelectOptions),
				new HiddenCountedLinkButtonBuilder(this.$i18n, this.getArticlesSelectOptions),
				...moreButtons && moreButtons.moreProButtons ? moreButtons.moreProButtons : [],

			])
		]
	}

	getDefaultButtons(moreButtons?: moreButtons): AvailableButtonsTypes {
		return [
			{
				name: this.$t('field_simple_buttons_title').toString(),
				buttons: [
					new Link(this.props),
					new Share(this.props),
					new SimpleHiddenMessageButton(this.props),
					new ShareTextButton(this.props),
					new Reaction(this.props),
					new PalmsReaction(this.props),
					new FingerUpReaction(this.props),
					new FingerDownReaction(this.props),
					...moreButtons && moreButtons.moreSimpleButton ? moreButtons.moreSimpleButton : [],
					this.formButtonButtonView,
				]
			},
			{
				name: this.$t('field_pro_buttons_title').toString(),
				buttons: [
					this.RunFlowButtonView,
					this.CommentsButtonView,
					new HiddenMessageButton(this.props),
					new HiddenCountedMessageButton(this.props),
					this.hiddenSubscriberLinkButtonView,
					this.hiddenCountedLinkButtonView,
					...moreButtons && moreButtons.moreProButtons ? moreButtons.moreProButtons : [],
				]
			}
		]
	}

	getDistributionsButtons(moreButtons?: moreButtons): AvailableButtonsTypes {
		return [
			{
				name: this.$t('field_simple_buttons_title').toString(),
				buttons: [
					new Link(this.props),
					new Share(this.props),
					new SimpleHiddenMessageButton(this.props),
					new ShareTextButton(this.props),
					...moreButtons && moreButtons.moreSimpleButton ? moreButtons.moreSimpleButton : [],
					this.formButtonButtonView,
				]
			},
			{
				name: this.$t('field_pro_buttons_title').toString(),
				buttons: [
					this.RunFlowButtonView,
					this.CommentsButtonView,
					new HiddenMessageButton(this.props),
					new HiddenCountedMessageButton(this.props),
					this.hiddenSubscriberLinkButtonView,
					this.hiddenCountedLinkButtonView,
					...moreButtons && moreButtons.moreProButtons ? moreButtons.moreProButtons : [],
				]
			}
		]
	}

	get distributionsButtons(): AvailableButtonsTypes {
		return this.getDistributionsButtons()
	}

	get defaultMessageEditorButtons(): AvailableButtonsTypes {
		return this.getDefaultButtons()
	}

	get flowButtons(): AvailableButtonsTypes {

		return [
			new RunFlowNodeButton(this.props),
			new Link(this.props),
			new Share(this.props),
			new FlowFormButton({ ...this.props, getForms: () => this.getFormsSelectOptions(), createFormButtonCallback: () => this.formCreateRoute }),
			this.RunFlowButtonView,
		]
	}

	get formCreateRoute() {
		return this.$router.push({
			name: 'Full_form',
			params: {
				actionType: BaseItemViewAction.New,
			}
		})
	}

	get captchaButton(): any {
		return [
			new CaptchaButton(this.props),
		]
	}

	get newCaptchaButton():any {
		return [
			new CaptchaButtonBuilder(this.$i18n),
		]
	}

	get newTextButton():any {
		return [
			new TextButtonBuilder(this.$i18n),
		]
	}

	get pmButtons(): any {
		return [
			this.RunFlowButtonView,
			new Share(this.props),
			new Link(this.props),
			new ShareTextButton(this.props),
			this.formButtonButtonView,
		]
	}

	get newPmButtons(): any {
		return [
			new RunFlowButtonBuilder(this.$i18n, this.resolveFlowSelectOption),
			new LinkButtonBuilder(this.$i18n),
			new ShareButtonBuilder(this.$i18n),
			new ShareTextButtonBuilder(this.$i18n),
			new FormButtonButtonBuilder(this.$i18n, this.getFormsSelectOptions),
		]
	}

	get RunFlowButtonView() {
		return new RunFlowButton({
			...this.props,
			getFlowsOptions: this.getFlowSelectOptions,
			getUtmSourcesOptions: this.getUtmSelectOptions,
			...!this.$store.getters.hasBoardLicense ? {
				tags: () => [ {
					text: 'Prime',
					color: 'rgba(var(--a-danger), 1)'
				} ]
			} : {}
		})
	}

	getFlowSelectOptions(): Promise<Array<SelectOptionData>> {
		return new Promise(resolve => {
			if (!this.$store.state.flowsState.flows) {
				this.$store.dispatch('requestFlows', { board_key: this.$store.state.boardsState.activeBoard?.board })
					.then(() => {
						resolve(this.$store.getters.flowOptions)
						return this.$store.getters.flowOptions
					})
			} else {
				resolve(this.$store.getters.flowOptions)
			}
		})
	}

	getUtmSelectOptions(): Promise<Array<SelectOptionData>> {
		return new Promise(resolve => {
			MetaDataService.getUtmSources('tg', { board_key: this.$store.state.boardsState.activeBoard?.board })
				.then(({ items }) => {
					resolve(items.map(utm => ({
						label: utm.value,
						value: utm.id
					})))
				})
				.catch((error) => {
					errorNotification(error)
					resolve([])
				})
		})
	}

	getFormsSelectOptions(): Promise<Array<SelectOptionData>> {
			if (this.$store.state.formsState.forms === null) {
				return this.$store.dispatch('getForms')
					.then(() => this.$store.getters.formsSelectOptions)
			}

			return this.$store.getters.formsSelectOptions
	}

	get CommentsButtonView() {
		return new CommentsButton({ botName: this.$store.state.boardsState.activeBoard?.title, ...this.props })
	}

	get hiddenSubscriberLinkButtonView(): HiddenSubscriberLinkButton {
		return new HiddenSubscriberLinkButton({ getArticlesFn: this.getArticlesSelectOptions, ...this.props })
	}

	get hiddenCountedLinkButtonView(): HiddenCountedLinkButton {
		return new HiddenCountedLinkButton({ getArticlesFn: this.getArticlesSelectOptions, ...this.props })
	}

	get formButtonButtonView(): FormButton {
		return new FormButton({
			...this.props,
			getFormsFn: this.getFormsSelectOptions
		})
	}

	async getArticlesSelectOptions(): Promise<Array<SelectOptionData>> {
		if (this.$store.state.articlesState.articles === null) {
			try {
				await this.$store.dispatch('requestArticles')
				return this.$store.getters.articlesOptions
			} catch {
				return []
			}
		} else {
			return this.$store.getters.articlesOptions
		}
	}

	get defaultChatMessageEditorButtons() {
		return [ Text ]
	}

	channelConfigSetup(args: FieldData): FieldData {
		args.model = this.$store.state.channelsState.activeChannel.config
		if (args.setter === undefined) {
			args.setter = (value: any): void => {
				this.$store.commit('pi/EXEC', {
					'key': args.key,
					'fn': () => {
						this.$set(args.model, args.key as string, value)
					},
				})
			}
		}
		return args
	}

	modelSetter(args: FieldData): FieldData {
		if ([ 'postfix_message', 'prefix_message', 'variants', 'message' ].includes(args.key) && !(args as FieldData & MultiMessageEditorWithMediaData).settingsStructure) {
			(args as FieldData & MultiMessageEditorWithMediaData).settingsStructure = {
				'disable_link_preview': false,
				'disable_notify': false,
				'pin': false,
				'remove_previous': false,
			}
		}

		args.setter = (value: any) => {
			if (value !== undefined) {
				this.$set(args.model, args.key, value)
			}
		}
		return args
	}

	get forwardMessageActionModeOptions(): Array<SelectOption> {
		return Object.values(ForwarMessageActionMode).map(mode => ({
			label: this.$t(`mode_option_${ mode.toLowerCase() }`),
			value: mode
		}))
	}
}
