var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('div',{staticClass:"hidden-counted-message-button"},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'emptyText',
          'prefix': 'hidden_counted_message_button_',
          'validation': 'required',
          'saveFieldKey': 'emptyText_hidden_button_click'
        },
      }}}),_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'hidden_counted_message_button_',
          'validation': 'required',
          'saveFieldKey': 'text_hidden_button_click'
        },
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'limit',
          'prefix': 'hidden_counted_message_button_',
          'validation': 'required',
          'min': 0,
          'saveFieldKey': 'limit_hidden_button_click'
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'grouped',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'onlyUnique',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'stickyResponce',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'popup',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('textarea-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'targetValue',
          'prefix': 'hidden_counted_message_button_',
          'counter': 200,
          'autoSize': {maxRows: 5, minRows: 5},
          'validation': 'required',
        }
      }}}),_c('textarea-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'afterTargetValue',
          'prefix': 'hidden_counted_message_button_',
          'counter': 200,
          'autoSize': {maxRows: 5, minRows: 5},
          'validation': 'required',
        }
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }