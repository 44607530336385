












































import HiddenTargetTypesViews
  from '@/components/ProjectButtons/includes/components/HiddenTargetTypesViews/HiddenTargetTypesViews.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { CommentsButtonStruct } from '@/components/ProjectButtons/CommentsButton/index'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

@Component({
  components: {
    HiddenTargetTypesViews,
    ValidationObserver,
  },
})
export default class CommentsButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ type: Object }) model!: CommentsButtonStruct

  @Prop({ type: String }) readonly botName!: string
}
