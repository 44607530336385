import { AccessorConditionBuilder } from "../AccessorConditionBuilder";
import { Label } from ".";
import { AccessorTypesEnum } from "@/components/Flow/Accessor/Conditions/types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { plainToInstance } from "class-transformer";

export default class LabelBuilder extends AccessorConditionBuilder {
    view: { setting: VueComponent; preview: VueComponent } = {
        setting: () => import('./LabelSetting.vue'),
        preview: () => import('./LabelPreview.vue'),
    }

    icon: string = 'label';

    model = plainToInstance(Label, {
        type: AccessorTypesEnum.Label,
        labels: []
    })
}