import { errorNotification } from '@/includes/services/NotificationService'

import { PostService } from '@/includes/services/PostService'

export default {
    requestDrafts: ({ commit, state }) => {
        return PostService.getSavedPosts('tg', { board_key: state.boardsState.activeBoard?.board })
            .then((data) => {
                commit('SET_DRAFTS_TO_STORE', data.posts)
                return data.posts
            })
            .catch(errorNotification)
            .finally(() => state.draftsState.loading = false)
    },
    removeDraft: ({ commit, state }, post_key: string) => {
        return PostService.deleteSavedPost('tg', { board_key: state.boardsState.activeBoard?.board, post_key })
            .then((data) => {
                commit('REMOVE_DRAFT', data.post_key.token_id)
            })
            .catch(errorNotification)
    },
    createDraft: ({ commit, state }, post) => {
        return PostService.savePost('tg', post)
            .then(post => {
                commit('ADD_DRAFT_TO_STORE', post.message)

                return post
            })
            .catch(errorNotification)
    }
}
