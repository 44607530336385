import { PropertiesService } from '@/includes/services/PropertiesService'
import { RootState } from '@/store/state'
import { AddBoardPropertyReq, DeleteBoardPropertyReq, RenameBoardPropertyReq } from '@/includes/types/Properties.types'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import i18n from '@/i18n/i18n'

import {
	BotProperty,
	PropertyFieldType
} from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import { Commit } from 'vuex'

export default {
	/**
	 * @deprecated
	 */
	createProperty({ commit, state }: { commit: Commit, state: RootState }, { name, type }: { name: string, type: PropertyFieldType}): Promise<number> {
		return new Promise(resolve => {
			PropertiesService.createProperty('tg', {
				board_key: state.boardsState.activeBoard!.board,
				type,
				name
			})
				.then(({ index }) => {
					commit('set_property_to_board', { name, type, id: index })
					resolve(index)
				})
				.then(() => {
					successNotification()
				})
				.catch(errorNotification)
		})
	},

	addBoardProperty({ commit, state }: { commit: Commit, state: RootState }, body: Omit<AddBoardPropertyReq, 'board_key'>): Promise<Array<BotProperty> | void> {
			return PropertiesService.addBoardProperty('tg', {
				board_key: state.boardsState.activeBoard!.board,
				...body
			})
				.then(({ items }) => {
					commit('update_board_properties', items)
					successNotification(i18n.t('property_notify_created').toString())

					return items
				})
				.catch(errorNotification)
	},

	renameBoardProperty({ commit, state }: { commit: Commit, state: RootState }, body: Omit<RenameBoardPropertyReq, 'board_key'>): Promise<Array<BotProperty>> {
		return new Promise(resolve => {
			PropertiesService.renameBoardProperty('tg', {
				board_key: state.boardsState.activeBoard!.board,
				...body
			})
				.then(({ items }) => {
					commit('update_board_properties', items)
					i18n.t('property_notify_removed').toString()
					resolve(items)
				})
				.then(() => {
					successNotification()
				})
				.catch(errorNotification)
		})
	},

	deleteBoardProperty({ commit, state }: { commit: Commit, state: RootState }, body: Omit<DeleteBoardPropertyReq, 'board_key'>): Promise<Array<BotProperty>> {
		return new Promise(resolve => {
			PropertiesService.deleteBoardProperty('tg', {
				board_key: state.boardsState.activeBoard!.board,
				...body
			})
				.then(({ items }) => {
					commit('update_board_properties', items)

					resolve(items)
				})
				.then(() => {
					successNotification()
				})
				.catch(errorNotification)
		})
	},

}
