var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-button-setup-view"},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'validation': 'required',
      },
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'url',
        'prefix': 'redirect_button_',
        'validation': 'required|tg_url_or_url_or_placeholder:require_protocol',
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }