




































import { IntegrationNodeCustom } from "./IntegrationCustom";
import Editor from '@/components/Flow/Editor';
import { IntegrationRequestTypeEnum } from './RequestTypes/types';

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter';
import { SelectOption } from "piramis-base-components/src/logic/types";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { snakeCase } from "lodash";

@Component({
  components: {
    SelectInput,
    TextInput
  },
})
export default class IntegrationSidebarCustom extends Mixins(ModelSetter) {
  @Prop() node!: IntegrationNodeCustom

  @Prop() editor!: Editor

  get typeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(this.node.integrationTitleKey(IntegrationRequestTypeEnum.SendMessage)).toString(),
        value: IntegrationRequestTypeEnum.SendMessage
      },
      {
        label: this.$t(this.node.integrationTitleKey(IntegrationRequestTypeEnum.ChangePoints)).toString(),
        value: IntegrationRequestTypeEnum.ChangePoints
      },
      {
        label: this.$t(this.node.integrationTitleKey(IntegrationRequestTypeEnum.ChangeReputation)).toString(),
        value: IntegrationRequestTypeEnum.ChangeReputation
      },
    ]
  }
}
