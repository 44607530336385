import { RootState } from '@/store/state'
import { MenuOptions } from '@/includes/types/MenuOptions.types'

export default {
    SET_MENU_OPTIONS(state: RootState, options: MenuOptions): void {
        state.menuOptionsState.menuOptions = options
    },
    CLEAR_MENU_OPTIONS(state: RootState): void {
        state.menuOptionsState.menuOptions = null
    },
}