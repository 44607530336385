

































































import { HiddenTargetType } from '../../helpers/HiddenTargetTypeOptions'

import Editor from 'piramis-base-components/src/components/Editor/Editor.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import CreateIfEmpty from 'piramis-base-components/src/components/CreateIfEmpty.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import Component from 'vue-class-component'
import { Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    CreateIfEmpty,
    Editor
  }
})
export default class HiddenTargetTypesViews extends Mixins(UseFields) {
  @VModel({ 'type': Object }) model!: string

  @Prop({ 'type': String }) modelKey!: string

  @Prop({ 'required': true }) hiddenTargetType !: HiddenTargetType

  @Prop({ 'type': Function }) readonly getArticlesFn!: () => Promise<Array<SelectOptionData>>

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  HiddenTargetType: any = HiddenTargetType

  gotoArticleCreate() {
    this.$router.push({
      name: 'article',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
        actionType: 'new'
      }
    })
  }

  setupFn(args: FieldData): FieldData {
    // eslint-disable-next-line no-return-assign
    args.setter = (value: any): any => this.model = Object.assign(this.model, { [args.key]: value })
    return args
  }
}
