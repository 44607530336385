















import { IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory';
import { LabelBuilder } from ".";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class LabelPreview extends Vue {
  @Prop() condition!: IterableListItem<LabelBuilder>

  get beautifiedLabels() {
    return this.condition.value.model.labels.map(l => {
      const label = this.$store.state.boardsState.activeBoard!.config.labels[l]

      return label ?? l
    })
  }
}
