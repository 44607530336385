var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":"","tag":"div"}},[(_vm.$store.state.boardsState.activeBoard && _vm.$store.state.boardsState.activeBoard.license_expired)?_c('a-alert',{staticClass:"mx-2 mb-2",attrs:{"type":"warning"}},[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('run_flow_button_tariff_empty_warning_message'))},slot:"message"})]):_vm._e(),_c('a-alert',[_c('div',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.description)},slot:"message"})]),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'validation': 'required',
        'disabled': !_vm.$store.getters.hasBoardLicense
      },
    }}}),_c('create-if-empty',{attrs:{"options":_vm.getFlowsOptions,"empty-message":_vm.$t('pi_flows_create_empty_message'),"disabled":!_vm.$store.getters.hasBoardLicense},on:{"onCreateClick":_vm.gotoFlowList}},[_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'flow',
          'options': _vm.getFlowsOptions,
          'multiple': false,
          'disabled': !_vm.$store.getters.hasBoardLicense,
          'validation': 'required',
          'clearable': false,
        },
      }}})],1),(_vm.$route.name === 'post')?_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'utm',
        'options': _vm.utmOptions,
        'multiple': false,
        'taggable': true,
        'clearable': false,
        'disabled': !_vm.$store.getters.hasBoardLicense,
        'validation': 'required',
        'createButton': {
          'title': _vm.$t('add_utm_source'),
          'callback': _vm.createCallback
        },
      },
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }