import { IntegrationRequest } from "../IntegrationRequest/IntegrationRequest";
import { IntegrationRequestTypeEnum } from "../types";

import { plainToInstance } from "class-transformer";

export class ChangeReputationRequest extends IntegrationRequest {
  type: IntegrationRequestTypeEnum = IntegrationRequestTypeEnum.ChangeReputation;

  value!: number

  lower_limit!: number
  upper_limit!: number

  reason!: string
}
