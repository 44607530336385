var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('div',{staticClass:"hidden-message"},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'hidden_message_button_',
          'validation': 'required',
          'saveFieldKey': 'text_hidden_button_subscribed'
        },
      }}}),_c('div',[_c('div',{staticClass:"mx-2 my-5 relative"},[_c('field-title',{staticClass:"mb-2",attrs:{"title-key":"hidden_message_subscribed_message","required":true}}),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":'subscribedMessage',"rules":"required","immediate":true}},[_c('a-textarea',{staticClass:"p-5",attrs:{"max-length":200,"rows":4},model:{value:(_vm.model.subscribedMessage),callback:function ($$v) {_vm.$set(_vm.model, "subscribedMessage", $$v)},expression:"model.subscribedMessage"}}),_c('a-tag',{staticClass:"textarea-counter-tag absolute mr-0 rounded-none"},[_vm._v(" "+_vm._s(((_vm.model.subscribedMessage.length) + " / 200"))+" ")])],1)],1),_c('div',{staticClass:"mx-2 my-5 relative"},[_c('field-title',{staticClass:"mb-2",attrs:{"title-key":"hidden_message_unsubscribed_message","required":true}}),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":'unsubscribedMessage',"rules":"required","immediate":true}},[_c('a-textarea',{staticClass:"p-5",attrs:{"max-length":200,"rows":4},model:{value:(_vm.model.unsubscribedMessage),callback:function ($$v) {_vm.$set(_vm.model, "unsubscribedMessage", $$v)},expression:"model.unsubscribedMessage"}}),_c('a-tag',{staticClass:"textarea-counter-tag absolute mr-0 rounded-none"},[_vm._v(" "+_vm._s(((_vm.model.unsubscribedMessage.length) + " / 200"))+" ")])],1)],1),_c('number-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            model: _vm.model,
            'key': 'daysCount',
            'prefix': 'hidden_message_button_',
            'validation': 'required',
            'saveFieldKey': 'daysCount_hidden_button_subscribed'
          },
        }}}),_c('div',{staticClass:"mx-2 my-5 relative"},[_c('field-title',{staticClass:"mb-2",attrs:{"title-key":"hidden_message_not_enough_subscribed_message","required":true}}),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":'notEnoughSubscribedMessage',"rules":"required","immediate":true}},[_c('a-textarea',{staticClass:"p-5",attrs:{"max-length":200,"rows":4},model:{value:(_vm.model.notEnoughSubscribedMessage),callback:function ($$v) {_vm.$set(_vm.model, "notEnoughSubscribedMessage", $$v)},expression:"model.notEnoughSubscribedMessage"}}),_c('a-tag',{staticClass:"textarea-counter-tag absolute mr-0 rounded-none"},[_vm._v(" "+_vm._s(((_vm.model.notEnoughSubscribedMessage.length) + " / 200"))+" ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }