import { Form } from '@/includes/types/Form.types'
import { RootState } from '@/store/state'

export default {
    SET_FORMS_TO_STORE(state: RootState, forms: Array<Required<Form>>): void {
        state.formsState.forms = forms
    },
    ADD_FORM(state: RootState, newForm: Required<Form>): void {
        if (state.formsState.forms === null) {
            state.formsState.forms = []
        }

        state.formsState.forms.push(newForm)
    },
    REMOVE_FORM(state: RootState, formKey: Form['key']): void {
        state.formsState.forms = state.formsState.forms!.filter(form => form.key !== formKey)
    },
    CLEAR_FORMS_FROM_STORE(state: RootState): void {
        state.formsState.forms = null
    },
    UPDATE_FORM_BY_KEY(state: RootState, newForm: Required<Form>): void {
        const idx = state.formsState.forms!.findIndex(form => form.key === newForm.key)

        state.formsState.forms!.splice(idx, 1, newForm)
    },
}
