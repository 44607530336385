var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('div',{staticClass:"hidden-message"},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'hidden_message_button_',
          'validation': 'required',
        },
      }}}),_c('textarea-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'subscribedMessage',
          'counter': 200,
          'autoSize': {maxRows: 5, minRows: 5},
          'validation': 'required',
        }
      }}}),_c('textarea-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'unsubscribedMessage',
          'counter': 200,
          'autoSize': {maxRows: 5, minRows: 5},
          'validation': 'required',

        }
      }}}),(_vm.model.notEnoughSubscribedMessage !== null)?_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'daysCount',
          'prefix': 'hidden_message_button_',
          'validation': 'required',
          'saveFieldKey': 'daysCount_hidden_button_subscribed',
          'min': 0
        },
      }}}):_vm._e(),(_vm.model.notEnoughSubscribedMessage !== null)?_c('textarea-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'notEnoughSubscribedMessage',
          'counter': 200,
          'autoSize': {maxRows: 5, minRows: 5},
          'validation': 'required',
        }
      }}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }