import { LabelsService } from '@/includes/services/LabelsService'
import { RootState } from '@/store/state'
import i18n from "@/i18n/i18n";
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

import { Commit } from 'vuex'

export default {
	createLabel({ commit, state }: { commit: Commit, state: RootState }, title): Promise<{ index: number }> {
		return new Promise((resolve, reject) => {
			const existLabels = Object.values(state.boardsState.activeBoard!.config.labels)

			if (!existLabels.includes(title)) {
				LabelsService.createLabel('tg', {
					board_key: state.boardsState.activeBoard!.board,
					title,
				})
					.then(({ labels, index }) => {
						commit('UPDATE_BOARD_CONFIG_FIELDS', { labels })

						successNotification(i18n.t('label_notify_created').toString())

					resolve({ index })
				})
			} else {
				reject(i18n.t('label_already_exists').toString())
			}
		})
	},

	deleteLabel({ commit }: { commit: Commit }, { board_key, index }: { board_key: string, index: number }) {
			return LabelsService.deleteLabel('tg', { board_key, index })
				.then(({ labels }) => {
					commit('UPDATE_BOARD_CONFIG_FIELDS', { labels })

					successNotification(i18n.t('label_notify_removed').toString())

					return labels
				})
				.catch(errorNotification)
	},

	renameLabel({ commit }: { commit: Commit }, { board_key, index, title }: { board_key: string, index: number, title: string }) {
		return LabelsService.renameLabel('tg', { board_key, index, title })
			.then(({ labels }) => {
				commit('UPDATE_BOARD_CONFIG_FIELDS', { labels })

				successNotification()

				return labels
			})
			.catch(errorNotification)
	},
}
