import buttonView from './HiddenCountedMessageButton.vue'
import setupView from './HiddenCountedMessageButtonSetupView.vue'
import Button, { ButtonStruct } from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";

type HiddenCountedMessageButtonDataType = {
  text: string
  emptyText: string
  grouped: boolean
  onlyUnique: boolean
  stickyResponce: boolean
  limit: number
  popup: boolean
  targetValue: string
  afterTargetValue: string
}

export default class HiddenCountedMessageButton extends Button {
  name = 'HiddenCountedMessageButton'

  setupView = setupView
  buttonView = buttonView

  data: HiddenCountedMessageButtonDataType = this.getRawData()

  structBuilder = HiddenCountedMessageButtonStruct

  icon = '✉️👆'

  getRawData(): HiddenCountedMessageButtonDataType {
    return {
      text: this.$i18n.t('initial_text_message_editor_hidden_counted_button').toString(),
      emptyText: '',
      grouped: false,
      onlyUnique: false,
      stickyResponce: true,
      limit: 0,
      popup: false,
      targetValue: '',
      afterTargetValue: '',
    }
  }
}

export class HiddenCountedMessageButtonStruct extends ButtonStruct {
  emptyText: string
  grouped: boolean
  onlyUnique: boolean
  stickyResponce: boolean
  limit: number
  popup: boolean
  targetValue: string
  afterTargetValue: string

  constructor(struct: HiddenCountedMessageButtonStruct) {
    super(struct)

    const {
      emptyText,
      grouped,
      onlyUnique,
      stickyResponce,
      limit,
      popup,
      targetValue,
      afterTargetValue,
    } = struct

    this.emptyText = emptyText
    this.grouped = grouped
    this.onlyUnique = onlyUnique
    this.stickyResponce = stickyResponce
    this.limit = limit
    this.popup = popup
    this.targetValue = targetValue
    this.afterTargetValue = afterTargetValue
  }
}
