import { RootState } from '@/store/state'
import {
	BotProperty,
	PropertyFieldType
} from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

export default {
	/**
	 * @deprecated
	 */
	set_property_to_board(state: RootState, property: { id: number, name: string, type: PropertyFieldType }): void {
		const activeBoard = state.boardsState.activeBoard!

		activeBoard.config.properties.push(Object.assign(property, { options: [] }))
	},
	update_board_properties(state: RootState, properties: Array<BotProperty>):void {
		if (state.boardsState.activeBoard) {
			state.boardsState.activeBoard.properties = properties
		}
	}
}
