import { IntegrationRequest } from "../IntegrationRequest/IntegrationRequest";
import { IntegrationRequestTypeEnum } from "../types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export class SendMessageRequest extends IntegrationRequest {
  type: IntegrationRequestTypeEnum = IntegrationRequestTypeEnum.SendMessage;

  @Type(() => MessageEditorWithMediaData)
  message!: Array<MessageEditorWithMediaData>
}