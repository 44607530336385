import { AccessorConditionBuilder } from "../AccessorConditionBuilder";
import { AccessorTypesEnum } from "@/components/Flow/Accessor/Conditions/types";
import { ChannelMember } from ".";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { plainToInstance } from "class-transformer";

export default class ChannelMemberBuilder extends AccessorConditionBuilder {
    view: { setting: VueComponent; preview: VueComponent } = {
        setting: () => import('./ChannelMemberSetting.vue'),
        preview: () => import('./ChannelMemberPreview.vue'),
    }

    icon: string = 'connect_without_contact';

    model = plainToInstance(ChannelMember, {
        type: AccessorTypesEnum.ChannelMember,
        targets: []
    })
}