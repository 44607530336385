import { RootState } from '@/store/state'
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'

export default {
	getMenuOptionValue: (state: RootState) => (option: MenuOptionsKeys) => {
		if (state.menuOptionsState.menuOptions) {
			return state.menuOptionsState.menuOptions[option]
		}

		return true
	},
}
