import { IntegrationRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/RequestTypes/types";
import { IntegrationRequestBuilder } from "../IntegrationRequest/IntegrationRequestBuilder";
import { SendMessageRequest } from "./SendMessageRequest";

import { plainToInstance } from "class-transformer";

export const SendMessageRequestBuilder = new class SendMessageRequestBuilder extends IntegrationRequestBuilder {
    type: IntegrationRequestTypeEnum = IntegrationRequestTypeEnum.SendMessage;

    settings = () => import('./SendMessageRequestSetting.vue')
    preview = () => import('./SendMessageRequestPreview.vue')

    getModel() {
        return plainToInstance(SendMessageRequest, {
            type: IntegrationRequestTypeEnum.SendMessage,
            token: '',
            message: [ {
                attachments: [],
                text: '',
                buttons: [],
                remove_previous: false,
                pin: false,
                disable_link_preview: false,
                disable_notify: false,
                topics: [],
                protect_content: false,
                send_after: 0,
                remove_after: 0
            } ]
        })
    }
}
