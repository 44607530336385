import BaseApi from '@/includes/services/BaseApi'
import {
    CreatedPost,
    MessageVariants,
    OrderedPostData,
    OriginalPostResponse,
    ProcessSuggestPostAction,
    RequestPost,
    RequestPostDraft,
    Style
} from '@/includes/types/Post.types'
import { AxiosRequestConfig } from 'axios'
import { PollPost, IPostMessage, QuizPost, Schedule, SplitType, PayedPostMessage } from 'piramis-base-components/src/shared/modules/posting/types'

export class PostService {
    static createPost(type: string, body: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>): Promise<{ post: CreatedPost }> {
        return BaseApi.sendRequest(type, 'createPost', body)
    }

    static savePost(type: string, body: any) {
        return BaseApi.sendRequest(type, 'savePost', body)
    }

    static deletePost(type: string, body: any) {
        return BaseApi.sendRequest(type, 'deletePost', body)
    }

    static getPosts(type: string, body: any): Promise<{ posts: Array<CreatedPost> }> {
        return BaseApi.sendRequest(type, 'getPosts', body)
    }

    static getOriginalPost(type: string, body: { board_key: string, post_key: string }): Promise<{ post: OriginalPostResponse }> {
        return BaseApi.sendRequest(type, 'getOriginalPost', body)
    }

    static getSavedPosts(type: string, body: any) {
        return BaseApi.sendRequest(type, 'getSavedPosts', body)
    }

    static testPost(type: string, body: { post: IPostMessage | PollPost | QuizPost | PayedPostMessage, style: Style, board_key: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'testPost', body)
    }

    static getPostInfo(type: string, body: { post_key: string, board_key: string }, config: Omit<AxiosRequestConfig, 'headers'> = {}): Promise<any> {
        return BaseApi.sendRequest(type, 'getpostinfo', body, {}, config)
    }

    static movePost(type: string, body: { board_key: string, post_key: string, time: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'movepost', body)
    }

    static deleteSavedPost(type: string, body: { board_key: string, post_key: string }): Promise<{ post_key: { token_id: number, token_code: string } }> {
        return BaseApi.sendRequest(type, 'deletesavedpost', body)
    }

    static getScheduleDates(type: string, body: { board_key: string, schedule: Schedule }): Promise<{ run_time: Array<string> }> {
        return BaseApi.sendRequest(type, 'getscheduledates', body)
    }

    static deletePublishedPost(type: string, body: { board_key: string, post_key: string }): Promise<{ post_key: string }> {
        return BaseApi.sendRequest(type, 'deletepublishedpost', body)
    }

    static editPublishedPost(type: string, body: { board_key: string, post_key: string, message: Array<MessageVariants>, style: Style }): Promise<{ post_key: string }> {
        return BaseApi.sendRequest(type, 'editpublishedpost', body)
    }

    static getOrderedPosts(type: string, body: { board_key: string, post_key?: string, order: string, type: string }): Promise<{posts:Array<OrderedPostData>}> {
        return BaseApi.sendRequest(type, 'GetOrderedPosts', body)
    }

    static suggestPost(type: string, body: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>): Promise<any> {
        return BaseApi.sendRequest(type, 'suggestpost', body)
    }

    static getSuggestPosts(type: string, body: any): Promise<any> {
        return BaseApi.sendRequest(type, 'getsuggestposts', body)
    }

    static getDeletedPosts(type: string, body: any): Promise<any> {
        return BaseApi.sendRequest(type, 'getdeletedposts', body)
    }

    static applySuggestPost(type:string, body:{ board_key:string, post_key:string, action: ProcessSuggestPostAction }):Promise<any> {
        return BaseApi.sendRequest(type, 'applySuggestPost', body)
    }

    static getNotifications(type:string, body = undefined):Promise<{count:number, suggest_posts: Array<{ board_key:string, post_key:string }>}> {
        return BaseApi.sendRequest(type, 'getnotifications', body)
    }

    static getSuggestPost(type:string, body: { board_key:string, post_key:string }):Promise<any> {
        return BaseApi.sendRequest(type, 'getSuggestPost', body)
    }

    static deleteSuggestPost(type:string, body: { board_key:string, post_key:string }):Promise<any> {
        return BaseApi.sendRequest(type, 'deleteSuggestPost', body)
    }

    static getPostPreview(type:string,
                          body: { board_key:string, post:CreatedPost['post']['message'], style: Style, apply_watermark:boolean },
                          config: Omit<AxiosRequestConfig, 'headers'> = {}): Promise<{post: IPostMessage & {split_type: SplitType}}> {
        return BaseApi.sendRequest(type, 'getPostPreview', body, {}, config)
    }

    static editPost(type:string, body: any):Promise<any> {
        return BaseApi.sendRequest(type, 'editpost', body)
    }
}

