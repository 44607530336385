import buttonView from './HiddenCountedLinkButton.vue'
import setupView from './HiddenCountedLinkButtonSetupView.vue'
import { HiddenTargetType } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import Button, { ButtonStruct, BaseButtonProps } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

type HiddenCountedLinkButtonDataType = {
    text: string
    emptyText: string
    targetType: HiddenTargetType
    targetValue: string
    afterTargetType: HiddenTargetType
    afterTargetValue: string
    limit: number
    grouped: boolean
    onlyUnique: boolean
}

export default class HiddenCountedLinkButton extends Button {
    name = 'HiddenCountedLinkButton'

    setupView = setupView
    buttonView = buttonView

    data: HiddenCountedLinkButtonDataType = this.getRawData()

    structBuilder = HiddenCountedLinkButtonStruct

    icon = '🔔 🕒'

    constructor(props: BaseButtonProps<{ getArticlesFn: () => Promise<Array<SelectOptionData>> }>) {
        super(props)
    }

    getRawData(): HiddenCountedLinkButtonDataType {
        return {
            text: this.$i18n.t('initial_text_message_editor_hidden_counted_button').toString(),
            emptyText: '',
            targetType: HiddenTargetType.Message,
            targetValue: '',
            afterTargetType: HiddenTargetType.Message,
            afterTargetValue: '',
            limit: 10,
            grouped: false,
            onlyUnique: true
        }
    }
}

export class HiddenCountedLinkButtonStruct extends ButtonStruct {
    emptyText: string
    targetType: HiddenTargetType
    targetValue: string
    afterTargetType: HiddenTargetType
    afterTargetValue: string
    limit: number
    grouped: boolean
    onlyUnique: boolean

    constructor(struct: HiddenCountedLinkButtonStruct) {
        super(struct)

        const {
            emptyText,
            targetType,
            targetValue,
            afterTargetType,
            afterTargetValue,
            limit,
            grouped,
            onlyUnique,
        } = struct

        this.emptyText = emptyText
        this.targetType = targetType
        this.targetValue = targetValue
        this.afterTargetType = afterTargetType
        this.afterTargetValue = afterTargetValue
        this.limit = limit
        this.grouped = grouped
        this.onlyUnique = onlyUnique
    }

}

