import { RootState } from '@/store/state'
import moment from 'moment'

export default {
    isChannelSet: (state: RootState) => state.channelsState.activeChannel !== null,
    channelLicenseType: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel
        const LICENSE_TYPES = {
            '0': '',
            '1': 'Business'
        }

        if (activeChannel) {
            return LICENSE_TYPES[activeChannel.license_type]
        }
    },
    isChannelPrivate: (state: RootState) => {
        return state.channelsState.activeChannel && state.channelsState.activeChannel?.username === undefined
    },
    hasChannelGroup: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        return activeChannel && activeChannel.linked_chat_id !== null
    },
    channelLicenseExpires: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        if (activeChannel) {
            return moment(activeChannel.license_time).diff(moment(), 'd') < 7
                && moment(activeChannel.license_time).isSameOrAfter(moment())
        }

        return false

    },
    licenseTimeIfNotFreeLicense: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        if (activeChannel) {
            return moment(activeChannel.license_time).format('L, HH:mm')
        }
    },
    isLicenseExpiring: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        if (activeChannel) {
            return !activeChannel.licence_expired && moment(activeChannel.license_time).diff(moment(), 'd') <= 3
        }
    }
}