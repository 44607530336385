var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-button-setup-view"},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'validation': 'required',
      },
    }}}),_c('create-if-empty',{attrs:{"options":_vm.getFormsFn,"empty-message":_vm.$t('pi_forms_create_empty_message')},on:{"onCreateClick":_vm.gotoFormCreate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var options = ref.options;
return [_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'key',
          'prefix': 'form_',
          'options': options,
          'validation': 'required',
          'clearable': false,
        }
      }}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }