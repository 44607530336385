import { Commit } from 'vuex'
import { errorNotification } from '@/includes/services/NotificationService'
import { PostService } from '@/includes/services/PostService'

export default {
    requestSuggestNotifications({ commit }: { commit:Commit }) {
        return PostService.getNotifications('tg')
            .then((response)=> {
                commit('setNotifications', response)
                return response
            })
            .catch((error) => {
                errorNotification(error)
                return error
            })
    }
}
