import buttonView from './CommentsButton.vue'
import setupView from './CommentsButtonSetupView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/New/ProjectButtonTypes'

import BaseButtonBuilder, {
  BaseButtonViewData
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons'
import {
  BaseButton
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'

import VueI18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

interface Props {
  botName: string
}

export default class CommentsButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.CommentsButton

  titleKey = ProjectButtonTypes.CommentsButton

  setupView = setupView
  buttonView = buttonView

  icon = '🗣️'

  data = new CommentsButtonData(this.$i18n.t('initial_text_message_editor_comments_button').toString())

  declare buttonProps: Props

  constructor(i18n: VueI18n, botName: string) {
    super(i18n, { botName })
  }

  getData(button?: CommentsButton): CommentsButtonData {
    return new CommentsButtonData(button ? button : this.$i18n.t('initial_text_message_editor_comments_button').toString())
  }

  create(): CommentsButton {
    return plainToInstance(CommentsButton, {
      ...this.data,
      type: ProjectButtonTypes.CommentsButton,
      guid: Guid.create().toString(),
    })
  }
}

export class CommentsButtonData implements BaseButtonViewData {
  emptyText = ''
  text = ''

  constructor(data: CommentsButton | string) {
      if (data instanceof CommentsButton || typeof data === 'object') {
        const {
          emptyText,
          text
        } = data

        this.emptyText = emptyText
        this.text = text
      } else {
        this.text = data
      }
  }
}

export class CommentsButton implements BaseButton {
  text!: string
  type!: ProjectButtonTypes.CommentsButton
  guid!: string
  emptyText!: string
}
