import { ChannelMember, ChannelMemberBuilder } from "./ChannelMember";
import { Label, LabelBuilder } from "./Label";
import { AccessorTypesEnum } from "./types";
import { AccessorCondition } from "@/components/Flow/Accessor/Conditions/AccessorCondition";
import { ClassConstructor } from "class-transformer";

export const TransformerClasses: Array<{name: AccessorTypesEnum, value: ClassConstructor<AccessorCondition> }> = [
  { name: AccessorTypesEnum.ChannelMember, value: ChannelMember },
  { name: AccessorTypesEnum.Label, value: Label }
]

export const FactoryBuilderClass = function () {

  const getBuilder = (type: AccessorTypesEnum) => {
    switch (type) {
      case AccessorTypesEnum.ChannelMember:
        return new ChannelMemberBuilder()
      case AccessorTypesEnum.Label:
        return new LabelBuilder()
    }
  }

  return {
    getBuilder
  }
}()
