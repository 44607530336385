



































































































import { hiddenTargetTypeOptions } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import HiddenTargetTypesViews
  from '@/components/ProjectButtons/includes/components/HiddenTargetTypesViews/HiddenTargetTypesViews.vue'
import { LimitedSubscriptionLinkButton } from '.'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import PiTabs from 'piramis-base-components/src/components/PiTabs.vue'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    PiTabs,
    HiddenTargetTypesViews,
    ValidationObserver,
  },
})
export default class LimitedSubscriptionLinkButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: LimitedSubscriptionLinkButton

  @Prop({ 'type': Function }) readonly getArticlesFn!: () => Promise<Array<SelectOptionData>>

  @Prop({ type: Function }) getHasChannelsContentSubscription!: () => boolean

  currentTab = 1

  tabs: Array<SelectOptionData> = [
    {
      label: this.$t('limited_subscription_link_subscribed').toString(),
      value: 1,
      icon: {
        iconPack: 'material-icons',
        name: 'bookmark'
      }
    },
    {
      label: this.$t('limited_subscription_link_unsubscribed').toString(),
      value: 2,
      icon: {
        iconPack: 'material-icons',
        name: 'bookmark_remove'
      }
    },
  ]

  onSubscribedTargetTypeChange(): void {
    this.model.subscribedTargetValue = ''
  }

  onUnsubscribedTargetTypeChange(): void {
    this.model.unsubscribedTargetValue = ''
  }

  hiddenTargetOptions(): Array<SelectOptionData> {
    return hiddenTargetTypeOptions().map((o) => {
      return {
        label: this.$t(o.label).toString(),
        value: o.value
      }
    })
  }

  created() {
    if (!this.getHasChannelsContentSubscription()) {
      this.model.text = ''
    }
  }
}
