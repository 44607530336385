import BaseApi from '@/includes/services/BaseApi'
import {
	Product,
	ProductState,
	EditProductModel,
	CreateProductModel,
} from 'piramis-base-components/src/components/Shop/Products/types'
import {
	CashRegisterModel,
	CashRegistersState,
	CreateCashRegisterModel,
	UpdateCashRegisterModel,
} from 'piramis-base-components/src/components/Shop/CashRegisters/types'
import { Notice } from 'piramis-base-components/src/components/Shop/Notices/types'
import { Report } from 'piramis-base-components/src/components/Shop/ProductReports/types'

export class ShopService {
	static createProduct(type: string, body: { board_key: string } & CreateProductModel): Promise<{ data: Product }> {
		return BaseApi.sendRequest(type, 'CreateProduct', body)
	}

	static deleteProduct(type: string, body: { board_key: string, id: number }): Promise<{ state: ProductState.Hidden | ProductState.Removed }> {
		return BaseApi.sendRequest(type, 'DeleteProduct', body)
	}

	static editProduct(type: string, body: { board_key: string } & EditProductModel): Promise<{ data: Product }> {
		return BaseApi.sendRequest(type, 'EditProduct', body)
	}

	static getProduct(type: string, body: { board_key: string, id: number }): Promise<Product> {
		return BaseApi.sendRequest(type, 'GetProduct', body)
	}

	static getProducts(type: string, body: { board_key: string }): Promise<{ items: Array<Product> }> {
		return BaseApi.sendRequest(type, 'GetProducts', body)
	}

	static getCashRegistries(type: string, body: { board_key: string }): Promise<{ items: Array<CashRegisterModel> }> {
		return BaseApi.sendRequest(type, 'GetCashRegistries', body)
	}

	static createCashRegister(type: string, body: { board_key: string} & CreateCashRegisterModel): Promise<{ data: CashRegisterModel }> {
		return BaseApi.sendRequest(type, 'CreateCashRegister', body)
	}

	static editCashRegister(type: string, body: { board_key: string } & UpdateCashRegisterModel): Promise<{ data: CashRegisterModel }> {
		return BaseApi.sendRequest(type, 'EditCashRegister', body)
	}

	static deleteCashRegister(type: string, body: { board_key: string, id: number }): Promise<{ state: CashRegistersState }> {
		return BaseApi.sendRequest(type, 'DeleteCashRegister', body)
	}

	static getNotices(type: string, body: { board_key: string, offset: number, limit: number, customer_id?: number, pay_status?: any }): Promise<{ items: Array<Notice>, count: number }> {
		return BaseApi.sendRequest(type, 'GetNotices', body)
	}

	static removeNotice(type: string, body: { board_key: string, id: number }): Promise<void> {
		return BaseApi.sendRequest(type, 'DeleteNotice', body)
	}

	static getProductsReport(type: string, body: { board_key: string, customer_id?: number, product_id?: number }): Promise<{ items: Array<Report> }> {
		return BaseApi.sendRequest(type, 'GetProductsReport', body)
	}

	static restoreProduct(type: string, body: { board_key: string, id: number }): Promise<void> {
		return BaseApi.sendRequest(type, 'RestoreProduct', body)
	}

	static restoreCashRegister(type: string, body: { board_key: string, id: number }): Promise<void> {
		return BaseApi.sendRequest(type, 'RestoreCashRegister', body)
	}
}
