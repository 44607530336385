import buttonView from './CaptchaButton.vue'
import setupView from './CaptchaButtonSetupView.vue'
import TextButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/TextButton/index'

export default class CaptchaButtonBuilder extends TextButtonBuilder {

    titleKey = 'CaptchaButton'

    setupView = setupView
    buttonView = buttonView

    icon = '✔'
}
