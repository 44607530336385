import { IntegrationRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/RequestTypes/types";
import { ChangeReputationRequest } from "./ChangeReputationRequest";
import { IntegrationRequestBuilder } from "../IntegrationRequest/IntegrationRequestBuilder";

import { plainToInstance } from "class-transformer";

export const ChangeReputationRequestBuilder = new class ChangeReputationRequestBuilder extends IntegrationRequestBuilder {
    type: IntegrationRequestTypeEnum = IntegrationRequestTypeEnum.ChangeReputation;

    settings = () => import('../components/ChangeActivitySettings.vue')
    preview = () => import('../components/ChangeActivityPreview.vue')

    getModel() {
        return plainToInstance(ChangeReputationRequest, {
            type: IntegrationRequestTypeEnum.ChangeReputation,
            token: '',
            value: 0,
            lower_limit: 0,
            upper_limit: 0,
            reason: '',
        })
    }
}
