import { RootState } from '@/store/state'
import { ChannelInfo, SlimBoardInfo, TargetState } from '@/includes/types/Board.types'
import { BoardTargetOptionsType, GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import stylesGetters from './styles/getters'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import moment from 'moment'

export default {
    isBoardSet: (state: RootState): boolean => !!state.boardsState.activeBoard,
    activeBoard: (state: RootState): SlimBoardInfo | null => state.boardsState.activeBoard,
    getBoardById: (state: RootState): GetBoardByIdType => (boardId: string) => {
        return state.boardsState.boards!.find(b => b.board.split('.')[0] === boardId)
    },
    activeBoardChannels: (state: RootState, getters): Array<ChannelInfo> => {
        if (getters.isBoardSet) {
            return getters.activeBoard.channels.filter(c => c.state === TargetState.Board)
        }

        return []
    },
    boardTargetOptions: (_: RootState, getters): Array<SelectOptionData> => {
        if (getters.isBoardSet) {
            return getters.activeBoardChannels.map((target: ChannelInfo) => {
                return {
                    'label': target.title,
                    'value': target.id,
                    'image': {
                        'src': target.avatar
                    }
                }
            })
        }

        return []
    },
    boardAdmins: (state: RootState, getters): Array<SelectOptionData> => {
        if (getters.isBoardSet) {
            return state.boardsState.activeBoard!.admins.map((a) => {
                return {
                    'label': a.name,
                    'value': a.user_id,
                }
            })
        }

        return []
    },

    sortedBoards: (state: RootState): Array<SlimBoardInfo> => {
        if (state.boardsState.boards) {
            return state.boardsState.boards.sort((a, b) => {
                if (a.channels.length > b.channels.length || a.weight > b.weight) {
                    return -1
                }
                if (a.channels.length < b.channels.length || a.weight < b.weight) {
                    return 1
                }
                return 0
            })
        }

        return []
    },
    boardLicenseType: (state: RootState) => {
        const board = state.boardsState.activeBoard

        const LICENSE_TYPES = {
            '0': '',
            '1': 'Prime'
        }

        if (board) {
            return LICENSE_TYPES[board.license_type]
        }
    },
    hasBoardLicense: (state: RootState, getters): boolean => {
        if (getters.isBoardSet) {
            return moment(getters.activeBoard.license_time).isAfter(moment())
        }

        return false
    },
    boardLicenseExpires: (state: RootState, getters): boolean => {
        if (getters.isBoardSet) {
            return moment(getters.activeBoard.license_time).diff(moment(), 'd') < 7
                && moment(getters.activeBoard.license_time).isSameOrAfter(moment())
        }

        return false
    },
    hasBotModerators: (state: RootState, getters): boolean => {
        if (getters.isBoardSet) {
            return getters.activeBoard.admins.some((admin) => admin.limits !== null)
        }

        return false
    },
    ...stylesGetters
}
