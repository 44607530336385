




































































































































import { hiddenTargetTypeOptions } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import HiddenTargetTypesViews
  from '@/components/ProjectButtons/includes/components/HiddenTargetTypesViews/HiddenTargetTypesViews.vue'
import { HiddenCountedLinkButton } from '.'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import PiTabs from 'piramis-base-components/src/components/PiTabs.vue'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

enum Tabs {
  before,
  after,
}

@Component({
  data() {
    return {
      Tabs,
    }
  },
  components: {
    PiTabs,
    HiddenTargetTypesViews,
    ValidationObserver,
  },
})
export default class HiddenCountedLinkButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: HiddenCountedLinkButton

  @Prop({ 'type': Function }) readonly getArticlesFn!: () => Promise<Array<SelectOptionData>>

  activeTab: Tabs = Tabs.before

  tabs: Array<SelectOptionData> = [
    {
      label: this.$t('hidden_target_type_before_limit').toString(),
      value: Tabs.before,
      icon: {
        iconPack: 'material-icons',
        name: 'battery_4_bar'
      }
    },
    {
      label: this.$t('hidden_target_type_after_limit').toString(),
      value: Tabs.after,
      icon: {
        iconPack: 'material-icons',
        name: 'battery_alert'
      }
    },
  ]

  onTargetTypeChange(): void {
    this.model.targetValue = ''
  }

  onAfterTargetTypeChange(): void {
    this.model.afterTargetValue = ''
  }

  hiddenTargetOptions(): Array<SelectOptionData> {
    return hiddenTargetTypeOptions().map((o) => {
      return {
        label: this.$t(o.label).toString(),
        value: o.value
      }
    })
  }
}
