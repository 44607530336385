import notificationGetters from './notifications/getters'
import boardGetters from './boards/getters'
import propertiesGetters from './properties/getters'
import labelsGetters from './labels/getters'
import shopGetters from './shop/getters'
import flowGetters from './flow/getters'
import channelGetters from './channel/getters'
import articlesGetters from './articles/getters'
import formsGetters from './forms/getters'
import draftsGetters from './drafts/getters'
import menuOptionsGetters from './menuOptions/getters'
import inviteLinks from './InviteLinks/getters'

const getters = {
    ...boardGetters,
    ...channelGetters,
    ...notificationGetters,
    ...propertiesGetters,
    ...labelsGetters,
    ...shopGetters,
    ...flowGetters,
    ...articlesGetters,
    ...formsGetters,
    ...draftsGetters,
    ...menuOptionsGetters,
    ...inviteLinks,
}

export default getters;
