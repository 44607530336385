




































import { FormAnswerButton } from './index'
import { InputSetups } from '@/mixins/input-setups'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import CreateIfEmpty from 'piramis-base-components/src/components/CreateIfEmpty.vue'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    CreateIfEmpty
  }
})
export default class FormAnswerButtonSetupView extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel({ 'type': Object }) model!: FormAnswerButton

  @Prop({ type: Function }) getFormsOptions!: () => Promise<Array<SelectOptionData>>

  @Prop() createFormButtonCallback!: () => void
}
