














































































































































import { UseFields } from 'piramis-base-components/src/components/Pi'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'
import { HiddenCountedMessageButtonStruct } from '@/components/ProjectButtons/HiddenCountedMessageButton/index'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class HiddenCountedMessageButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: HiddenCountedMessageButtonStruct
}
