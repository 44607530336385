var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('div',{staticClass:"simple-hidden-message"},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'simple_hidden_message_button_',
          'validation': 'required',
          'saveFieldKey': 'text_simple_hidden_button_subscribed'
        },
      }}}),_c('div',{staticClass:"my-3 py-1 relative"},[_c('field-title',{staticClass:"mb-2",attrs:{"title-key":"simple_hidden_message_subscribed_message","required":true}}),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":'subscribedMessage',"rules":"required","immediate":true}},[_c('a-textarea',{staticClass:"p-5",attrs:{"max-length":200,"rows":4},model:{value:(_vm.model.subscribedMessage),callback:function ($$v) {_vm.$set(_vm.model, "subscribedMessage", $$v)},expression:"model.subscribedMessage"}}),_c('a-tag',{staticClass:"textarea-counter-tag absolute mr-0 rounded-none"},[_vm._v(" "+_vm._s(((_vm.model.subscribedMessage.length) + " / 200"))+" ")])],1)],1),_c('div',{staticClass:"my-3 py-1 relative"},[_c('field-title',{staticClass:"mb-2",attrs:{"title-key":"simple_hidden_message_unsubscribed_message","required":true}}),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":'unsubscribedMessage',"rules":"required","immediate":true}},[_c('a-textarea',{staticClass:"p-5",attrs:{"max-length":200,"rows":4},model:{value:(_vm.model.unsubscribedMessage),callback:function ($$v) {_vm.$set(_vm.model, "unsubscribedMessage", $$v)},expression:"model.unsubscribedMessage"}}),_c('a-tag',{staticClass:"textarea-counter-tag absolute mr-0 rounded-none"},[_vm._v(" "+_vm._s(((_vm.model.unsubscribedMessage.length) + " / 200"))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }