import store from '@/store/store'
import boardsActions from "./boards/actions"
import activeChannel from './channel/actions'
import articlesActions from './articles/actions'
import flowsActions from './flow/actions'
import statisticsActions from './statistics/actions'
import notificationActions from './notifications/actions'
import propertiesActions from './properties/actions'
import labelsAction from './labels/actions'
import shopAction from './shop/actions'
import formsActions from './forms/actions'
import draftsActions from './drafts/actions'
import menuOptionsActions from './menuOptions/actions'
import inviteLinksAction from './InviteLinks/actions'

import { Commit } from 'vuex'

const actions = {
	updateTargetsWithoutLinkedGroup({ commit, getters }: { commit: Commit, getters:any }, targets: Array<number>): void {
		if (targets && targets.length) {
			const boardTargets = getters.activeBoardChannels.filter(target => targets.includes(target.id) && target.linked_chat_id === null)

			commit('UPDATE_TARGETS_WITHOUT_LINKED_GROUP', boardTargets)
		} else {
			commit('UPDATE_TARGETS_WITHOUT_LINKED_GROUP', null)
		}
	},
	clearBotStores: () => {
		store.commit('clear_active_board_config')
		store.commit('CLEAR_MENU_OPTIONS')

		store.commit('CLEAR_FLOWS_FROM_STORE')
		store.commit('CLEAR_FORMS_FROM_STORE')
		store.commit('CLEAR_ARTICLES_FROM_STORE')
		store.commit('CLEAR_DRAFTS_FROM_STORE')
		store.commit('reset_shop_state_fields')
		store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', null)
	},
	// posts planner
	...boardsActions,
	// active channel
	...activeChannel,
	// articles
	...articlesActions,
	// flows
	...flowsActions,
	...statisticsActions,
	...notificationActions,
	...propertiesActions,
	...labelsAction,
	...shopAction,
	...formsActions,
	...draftsActions,
	...menuOptionsActions,
	...inviteLinksAction,
};

export default actions;
