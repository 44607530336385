var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"label-setting"},[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'labels',
        'options': _vm.$store.getters.getLabelsOptions,
        'prefix': 'flow_accessor_',
        'clearable': false,
        'multiple': true,
        'validation': 'required'
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }