








import DefaultButtonView
  from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/components/DefaultButtonView/DefaultButtonView.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  'components': {
    DefaultButtonView,
  },
})
export default class FormAnswerButtonView extends Vue {
  @Prop({ type: String }) readonly text!: string

  @Prop({ type: String }) readonly icon!: string

}
