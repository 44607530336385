import actions          from "./actions";
import mutations        from "./mutations";
import state, { RootState } from './state'
import getters 			from './getters'
import metaModule       from "@/store/modules/metaModule";

import { store as pi } from 'piramis-base-components/src/components/Pi'

import Vue              from "vue";
import Vuex             from "vuex";

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
	mutations,
	getters,
	state,
	actions,
	modules: {
		metaModule,
		pi,
	},
	strict : false
});
