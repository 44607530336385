
































































import { RunFlowButtonStruct } from '@/components/ProjectButtons/RunFlowButton/index'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import CreateIfEmpty from 'piramis-base-components/src/components/CreateIfEmpty.vue'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: {
    CreateIfEmpty,
    ValidationObserver,
  },
})
export default class RunFlowButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: RunFlowButtonStruct

  @Prop({ type: Function }) readonly getFlowsOptions!: () => Promise<Array<SelectOptionData>>

  @Prop({ type: Function }) readonly getUtmSourcesOptions!: () => Promise<Array<SelectOptionData>>

  utmOptions:Array<SelectOption> = []

  createCallback({ label }: Pick<SelectOption, 'label'>):void {
    this.utmOptions.push({ label, value: label })
    this.model.utm = label
  }

  gotoFlowList() {
    this.$router.push({
      params: {
        [EntityTypes.BOT_ID]: this.$route.params[EntityTypes.BOT_ID],
      },
      name: "Flows_List"
    })
  }

  get description(): string {
    if (this.$route.name !== 'Flow') {
      return this.$t('run_flow_button_help_message', { 0: this.$store.state.boardsState.activeBoard.title }).toString()
    } else {
      return this.$t('run_flow_button_help_message_on_flow_page').toString()
    }
  }

  async setFlowsOptions():Promise<void> {
    this.utmOptions = await this.getUtmSourcesOptions()
  }

  created(): void {
    this.setFlowsOptions()
  }
}
