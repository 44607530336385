import { Product } from 'piramis-base-components/src/components/Shop/Products/types'
import { CashRegisterModel } from 'piramis-base-components/src/components/Shop/CashRegisters/types'

export type ShopState = {
	products: Array<Product> | null
	cashRegisters: Array<CashRegisterModel> | null
}

export const shopState: ShopState = {
	products: null,
	cashRegisters: null,
}
