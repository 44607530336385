import { RootState } from '@/store/state'
import { MenuOptionsKeys } from '@/includes/types/MenuOptions.types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

export default {
    hasArticles: (state: RootState, getters) => {
        if (state.articlesState.articles === null) {
            return getters.getMenuOptionValue(MenuOptionsKeys.Articles)
        }

        return state.articlesState.articles && state.articlesState.articles.length > 0
    },
    articlesOptions(state: RootState, getters): Array<SelectOptionData> | null {
        if (getters.hasArticles && state.articlesState.articles) {
            return state.articlesState.articles.map(article => ({
                label: article.title,
                value: article.key
            }))
        }

        return []
    },

}
