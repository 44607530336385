import { AccessorCondition } from "../AccessorCondition";
import { AccessorTypesEnum } from "../types";

export class Label extends AccessorCondition {
  type: AccessorTypesEnum = AccessorTypesEnum.Label;

  labels!: Array<number>

  validate(): Promise<boolean> {
    return Promise.resolve(!!this.labels.length);
  }
}
