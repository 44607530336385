var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'prefix': 'share_text_button_',
        'validation': 'required',
      },
    }}}),_c('editor-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'message',
        'prefix': 'share_text_button_',
        'validation': 'required_if_not:url',
      },
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'url',
        'prefix': 'share_text_button_',
        'validation': 'required_if_not:message|urlBraces',
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }