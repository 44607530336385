import buttonView from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text/TextButtonView.vue'
import setupView from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text/TextSetupView.vue'
import Button from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'

export default class CaptchaButton extends Button {
    name = 'TextButton'

    title = 'CaptchaButton'

    setupView = setupView
    buttonView = buttonView

    data: { text: string } = this.getRawData()

    icon = '✔'

    getRawData(): { text: string } {
        return { text: this.$i18n.t('initial_text_approve_join_req').toString() }
    }
}
