import BaseApi from '@/includes/services/BaseApi'
import { errorNotification } from '@/includes/services/NotificationService'
import { ProjectFlowConfig } from "@/components/Flow/Accessor/types";

import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'
import { FlowData } from 'piramis-base-components/src/components/Flow/logic/types/GeneralTypes'
import { FlowTemplateNames } from 'piramis-base-components/src/components/FlowsTemplates/types'
import { GetUsersInfo } from 'piramis-base-components/src/models/getUserInfo/GetUserInfo'
import { ChannelInfoResponse } from 'piramis-base-components/src/models/CheckChannels'
import { FlowItem } from 'piramis-base-components/src/components/FlowsList/types'

import axios from 'axios'
import { plainToInstance } from 'class-transformer'

export class FlowsService {
	static getFlowThumbnailData(type: string, body: { request: string }): Promise<FlowResponse> {
		return BaseApi.sendRequest(type, 'GetFlowThumbnailData', body)
	}

	static getBoardFlows(type: string, body: { board_key }): Promise<{ flows: Array<FlowItem> }> {
		return BaseApi.sendRequest(type, 'GetBoardFlows', body)
	}

	static createFlow(type: string, body: { board_key: string, title: string, scheme: any }): Promise<FlowItem> {
		return BaseApi.sendRequest(type, 'CreateFlow', body)
	}

	static getFlow(type: string, body: { board_key: string, flow_key: string }): Promise<FlowResponse> {
		return  BaseApi.sendRequest(type, 'GetFlow', body)
	}

	static deleteFlow(type: string, body: { board_key: string, flow_key: string }): Promise<DeleteFlow> {
		return BaseApi.sendRequest(type, 'DeleteFlow', body)
	}

	static updateFlow(type: string, body: { board_key: string, flow_key: string, title: string, scheme: any }): Promise<FlowResponse> {
		return BaseApi.sendRequest(type, 'UpdateFlow', body)
	}

	static getFlowThumbnail(type: string, body: { board_key: string, flow_key: string }): Promise<any> {
		return new Promise((resolve) => {
			axios.get(`${ PostApi.getApiUrl() }${ type }/GetFlowThumbnail?board_key=${ body.board_key }&flow_key=${ body.flow_key }`, {
				withCredentials: true,
			}).then((res) => {
				resolve(res)
			}).catch((error) => {
				errorNotification(error)
			})
		})
	}

	static checkChannels(type: string, body: { board_key: string, values: Array<string> }): Promise<CheckChannelsResponse> {
		return BaseApi.sendRequest(type, 'CheckChannels', body)
	}

	static getUsersInfo(type: string, body: { board_key: string, values: Array<string | number> }): Promise<GetUsersInfoResponse> {
		return BaseApi.sendRequest(type, 'GetUsersInfo', body)
	}

	static createFlowLink(type: string, body: { board_key: string, flow_key: string, utm: string }): Promise<FlowItem> {
		return BaseApi.sendRequest(type, 'CreateFlowLink', body)
	}

	static renameFlow(type: string, body: { board_key: string, key: string, title: string }): Promise<FlowResponse> {
		return BaseApi.sendRequest(type, 'RenameFlow', body)
	}

	static runTestingFlow(type: string, body: { board_key: string, flow_key: string }): Promise<void> {
		return BaseApi.sendRequest(type, 'RunFlow', body)
	}

	static createTemplateFlow(type: string, body: CreateTemplateFlowRequestBody): Promise<{ flow: FlowItem }> {
		return BaseApi.sendRequest(type, 'CreateTemplateFlow', body)
	}

	// NEW FLOW REQUESTS
	static async newGetFlow(type: string, body: { board_key: string, flow_key: string }): Promise<{ instance: ProjectFlowConfig, plain: any }> {
		const { flow } = await BaseApi.sendRequest(type, 'GetFlow', body)

		return { instance: plainToInstance(ProjectFlowConfig, flow), plain: flow }
	}

	static async newGetUsersInfo(type: string, body: { board_key: string, values: Array<string | number> }): Promise<GetUsersInfo> {
		const info = await BaseApi.sendRequest(type, 'GetUsersInfo', body)

		return plainToInstance(GetUsersInfo, info)
	}

	static async getChannelInfo(type: string, body: { board_key: string, values: Array<string> }): Promise<ChannelInfoResponse> {
		const info = BaseApi.sendRequest(type, 'CheckChannels', body)
		return plainToInstance(ChannelInfoResponse, info)
	}
}

export type CreateTemplateFlowRequestBody = {
	board_key: string
	title: string
	template: FlowTemplateNames
	data?: {
		channel: number
	}
}

export type SubscriptionTarget = SuccessTarget | FailTarget

export type SuccessTarget = {
	status: true
	info : {
		id: number
		login: string
		title: string
		type: 'CHANNEL' | 'FORUM'
	}
}

export type FailTarget = {
	status: false
	error: string
}

export type SuccessGetUser = {
	value: string | number
	info: {
		id: number
		name: string
		login: string
		language: string
	}
}

export type CheckChannelsResponse = {
	data: Array<{value: string} & SubscriptionTarget>
}

export type GetUsersInfoResponse = {
	data: Array<SuccessGetUser>
}

export type FlowResponse = {
	flow: FlowData
}

export type DeleteFlow = {
	key: {
		token_id: number
		token_code: string
	}
}
