import { Board, SlimBoardInfo } from '@/includes/types/Board.types'

export type BoardsState = {
  boards: Array<SlimBoardInfo> | null
  activeBoard: Board | null
}

export const boardsState:BoardsState ={
  boards: null,
  activeBoard: null,
}
