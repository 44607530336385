import { FlowItem } from 'piramis-base-components/src/components/FlowsList/types'

export type FlowsState = {
	flows: Array<FlowItem> | null
	getBoardFlowsPromise: Promise<Array<FlowItem>> | null
}

export const flowsState: FlowsState = {
	flows: null,
	getBoardFlowsPromise: null
}
