var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hidden-target-types-views"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.hiddenTargetType === _vm.HiddenTargetType.Message)?_c('editor-input',{attrs:{"setup":{
        'func': _vm.setupFn,
        'args': {
          model: _vm.model,
          'key': _vm.modelKey,
          'prefix': 'button_reaction_',
          'validation': 'required',
          disabled: _vm.disabled,
        },
      }}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.hiddenTargetType === _vm.HiddenTargetType.Redirect)?_c('text-input',{attrs:{"setup":{
        'func': _vm.setupFn,
        'args': {
          model: _vm.model,
          'key': _vm.modelKey,
          'prefix': 'button_check_',
          'validation': 'required|urlBraces',
          disabled: _vm.disabled,
        },
      }}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.hiddenTargetType === _vm.HiddenTargetType.Article)?[_c('create-if-empty',{attrs:{"options":_vm.getArticlesFn,"empty-message":_vm.$t('articles_create_empty_message')},on:{"onCreateClick":_vm.gotoArticleCreate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var options = ref.options;
return [_c('select-input',{attrs:{"setup":{
            'func': _vm.setupFn,
            'args': {
              model: _vm.model,
              'key': _vm.modelKey,
              'prefix': 'button_hidden_target_',
              'validation': 'required',
              'clearable': false,
              'options': options,
              disabled: _vm.disabled,
            },
          }}})]}}],null,false,1694995317)})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }