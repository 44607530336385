

















































































































































import { hiddenTargetTypeOptions } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import HiddenTargetTypesViews
  from '@/components/ProjectButtons/includes/components/HiddenTargetTypesViews/HiddenTargetTypesViews.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { HiddenSubscriberLinkButtonStruct } from '@/components/ProjectButtons/HiddenSubscriberLinkButton/index'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

enum Tabs {
  subscribed,
  unsubscribed,
  enough_subscribed,
}

@Component({
  data() {
    return {
      Tabs,
    }
  },
  components: {
    HiddenTargetTypesViews,
    ValidationObserver,
  },
})
export default class HiddenSubscriberLinkButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: HiddenSubscriberLinkButtonStruct

  @Prop({ 'type': Function }) readonly getArticlesFn!: () => Promise<Array<SelectOptionData>>

  @Watch('model.notEnoughSubscribedTargetType')
  onNotEnoughSubscribedTargetTypeChange(): void {
    this.model.notEnoughSubscribedTargetValue = ''
  }

  @Watch('model.subscribedTargetType')
  onSubscribedTargetTypeChange(): void {
    this.model.subscribedTargetValue = ''
  }

  @Watch('model.unsubscribedTargetType')
  onUnsubscribedTargetTypeChange(): void {
    this.model.unsubscribedTargetValue = ''
  }

  activeTab: Tabs = Tabs.subscribed

  async hiddenTargetOptions(): Promise<Array<SelectOptionData>> {
    return hiddenTargetTypeOptions().map((o) => {
      return {
        label: this.$t(o.label).toString(),
        value: o.value
      }
    })
  }
}
