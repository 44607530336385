import IntegrationSampleNodeCustom from "./IntegrationSampleNodeCustom.vue";
import IntegrationSidebarCustom from "./IntegrationSidebarCustom.vue";
import { IntegrationRequest } from "./RequestTypes/IntegrationRequest/IntegrationRequest";
import { transformerSubtypes } from "./RequestTypes/transformerSubtypes";
import { IntegrationRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/RequestTypes/types";
import { SendMessageRequestBuilder } from "./RequestTypes/SendMessageRequest/SendMessageRequestBuilder";
import { ChangePointsRequestBuilder } from "./RequestTypes/ChangePointsRequest/ChangePointsRequestBuilder";
import { ChangeReputationRequestBuilder } from "./RequestTypes/ChangeReputationRequest/ChangeReputationRequestBuilder";

import { CustomNode, NodeTypes } from "piramis-base-components/src/components/Flow/new/logic/Nodes";
import IntegrationNodeBuilder, { IntegrationNode, IntegrationNodeData } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Additionally/Integration/logic/IntegrationNode'
import { BaseNodeModel } from "piramis-base-components/src/components/Flow/new/logic/Nodes/BaseNode/BaseNodeModel";

import { instanceToInstance, plainToInstance, Type } from "class-transformer";
import { keyBy, snakeCase } from "lodash";
import VueI18n from "vue-i18n";

export default class IntegrationNodeBuilderCustom extends IntegrationNodeBuilder {

  sidebarComponent = IntegrationSidebarCustom;

  constructor(i18n: VueI18n) {
    super(i18n, IntegrationSampleNodeCustom, IntegrationNodeCustom);
  }

  getNodeData(): IntegrationNodeDataCustom {
    return plainToInstance(IntegrationNodeDataCustom, {
      type: NodeTypes.Integration,
      source: 1,
      data: SendMessageRequestBuilder.getModel(),
      fail_output: CustomNode.LastSocketId += 1,
    })
  }
}

export class IntegrationNodeCustom extends IntegrationNode {
  declare data: IntegrationNodeDataCustom

  dataBackup: IntegrationRequest | undefined

  constructor(name: NodeTypes.Integration, model: BaseNodeModel) {
    super(name, model);

    if (model.data instanceof IntegrationNodeDataCustom) {
      this.dataBackup = instanceToInstance(model.data.data)
    }
  }

  builders = keyBy([
      SendMessageRequestBuilder,
      ChangePointsRequestBuilder,
      ChangeReputationRequestBuilder
    ], 'type')

  get token() {
    return this.data.data.token
  }

  set token(value: string) {
    this.data.data.token = value.trim()
  }

  get requestType() {
    return this.data.data.type
  }

  set requestType(type: IntegrationRequestTypeEnum) {
    if (this.dataBackup && this.dataBackup.type === type) {
      this.data.data = instanceToInstance(this.dataBackup)
    } else {
      const builder = this.builders?.[type]

      if (builder) {
        this.data.data = instanceToInstance(this.builders[type].getModel())
      } else {
        this.data.data.type = type
      }
    }
  }

  integrationTitleKey(type: IntegrationRequestTypeEnum) {
    return `integration_type_${ snakeCase(type).toLowerCase() }`
  }
}

export class IntegrationNodeDataCustom extends IntegrationNodeData {
  declare type: NodeTypes.Integration

  @Type(() => IntegrationRequest, {
    keepDiscriminatorProperty: true,
    discriminator: {
      property: 'type',
      subTypes: transformerSubtypes
    }
  })
  declare data: IntegrationRequest
}
